/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

/** custom imports */
import { SuggestionsService } from './services/suggestions.service';
import * as actions from './suggestions.actions';
import Suggestion from '@apps/leap/src/app/shared/interfaces/suggestion.interface';

@Injectable()
export class SuggestionsEffects {
    constructor(private actions$: Actions, private suggestionsService: SuggestionsService) {}

    getSuggestions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getSuggestionsRequest),
            switchMap(({ id, query, limit }: { id: string; query: string; limit: number }) =>
                this.suggestionsService.getSuggestions(query, limit).pipe(
                    map((suggestions: Suggestion[]) =>
                        actions.getSuggestionsSuccess({ id, suggestions }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getSuggestionsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
