import { Injectable } from '@angular/core';
import { debounce } from 'lodash';

/** custom imports */
import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenService } from './token.service';
import { AuthFacade } from '../auth.facade';
import TokenTypes from '../enums/token-types.enum';

@Injectable()
export class RefreshTokenService {
    refreshTimer: ReturnType<typeof setTimeout> = null;
    refreshTime: number = 5 * 60 * 1000;

    focusListener: EventListener;

    constructor(
        private jwtHelperService: JwtHelperService,
        private tokenService: TokenService,
        private authFacade: AuthFacade,
    ) {}

    /** Returns the refreshTimer */
    getTimer(): ReturnType<typeof setTimeout> {
        return this.refreshTimer;
    }

    /**
     * Sets a timer to fire after a given time. It calls auth facade refreshToken()
     * method. Then calls itself to create a loop which will keep the
     * token refreshed during the user session.
     */
    setTimer(): void {
        this.authFacade.refreshToken();
        this.refreshTimer = setTimeout(() => {
            this.setTimer();
        }, this.refreshTime);
    }

    /**
     * Stops the refresh token loop by clearing the refreshTimer
     * timeout.
     */
    removeTimer(): void {
        clearTimeout(this.refreshTimer);
        this.refreshTimer = null;
    }

    /** Returns the focusListener */
    getFocusListener(): any {
        return this.focusListener;
    }

    /**
     * Sets up a focus event listener on the window to monitor when the window gains focus.
     * The listener is debounced with a 400ms delay to prevent rapid consecutive calls.
     *
     * When the window gains focus, the event listener triggers the refreshExpiredToken().
     *
     * This is used in addition to the refreshTimer to account for the possibility that the
     * setTimeout will not trigger its callback, as a result of incorrect time counting when the
     * window has lost its focus.
     */
    setFocusListener(): void {
        this.focusListener = debounce((): void => {
            this.refreshExpiredToken();
        }, 400);

        window.addEventListener('focus', this.focusListener);
    }

    /** Removes the focusListener */
    removeFocusListener(): void {
        window.removeEventListener('focus', this.focusListener);
    }

    /** Refreshes the access token if it has expired */
    refreshExpiredToken(): void {
        const accessToken: string = this.tokenService.getToken(TokenTypes.access);

        if (!this.jwtHelperService.isTokenExpired(accessToken)) {
            return;
        }

        this.authFacade.refreshToken();
    }
}
