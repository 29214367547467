import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** Services - Facades */
import { AuthService } from '../services/auth.service';
import { RedirectService } from '../../../layout/services/redirect.service';
import { AuthFacade } from '@leap-store/core/src/lib/data/auth/auth.facade';

/** Enums */
import TokenTypes from '@leap-store/core/src/lib/data/auth/enums/token-types.enum';

@Injectable()
/** The name'NonAuthGuard' was chosen to indicate that it applies to users who are not authenticated */
export class NonAuthGuard implements CanActivate {
    constructor(
        private authService: AuthService,
        private redirectService: RedirectService,
        private authFacade: AuthFacade,
    ) {}

    /**
     * Determines whether the user can activate the protected route by checking their authentication status.
     *
     * - If the user is authenticated, they are redirected based on their terms of use acceptance,
     *   and the route activation is blocked.
     * - If the user is not authenticated, the logout process is triggered to clear stale tokens (access and refresh),
     *   and the user is allowed to access the requested route (e.g., /auth/).
     *
     * This ensures that stale tokens are cleared and that unauthenticated users are properly routed.
     */
    canActivate(): Observable<boolean> {
        return this.authService.isUserAuthenticated().pipe(
            map((isUserAuthenticated: boolean) => {
                if (isUserAuthenticated) {
                    this.redirectService.redirectBasedOnTermsOfUseAcceptance();
                    return false;
                }
                this.authFacade.logout([TokenTypes.access, TokenTypes.refresh]);
                return true;
            }),
        );
    }
}
