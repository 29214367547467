/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

/** custom imports */
import { ArticlesService } from './services/articles.service';
import { BookmarksService } from '@leap-store/core/src/lib/data/bookmarks/services/bookmarks.service';
import * as actions from './articles.actions';
import FileExtension from '@leap-common/enums/file-extension.enum';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import Discovery from '@apps/leap/src/app/shared/enums/discovery.enum';
import EffectsModel from '@apps/leap/src/app/shared/enums/effects-model.enum';
import ArticleInfo from './interfaces/article-info.interface';
import PaginatedArticles from './interfaces/paginated-articles.interface';
import Article from './interfaces/article.interface';
import BookmarkIds from '../bookmarks/interfaces/bookmark-ids.interface';

@Injectable()
export class ArticlesEffects {
    constructor(
        private actions$: Actions,
        private articlesService: ArticlesService,
        private bookmarksService: BookmarksService,
    ) {}

    performQuery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.performQueryRequest),
            switchMap(
                ({
                    query,
                    filters,
                    pageSize,
                    pageIndex,
                    searchQuery,
                    sortingOptions,
                }: {
                    query: string;
                    filters: ExecutionFilters;
                    pageSize: number;
                    pageIndex: number;
                    searchQuery: string;
                    sortingOptions: SortingOptions;
                }) =>
                    this.articlesService
                        .performQuery(
                            query,
                            filters,
                            pageSize,
                            pageIndex,
                            searchQuery,
                            sortingOptions,
                        )
                        .pipe(
                            map((paginatedArticles: PaginatedArticles) =>
                                actions.performQuerySuccess({ paginatedArticles }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.performQueryFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    downloadQueryArticles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.downloadQueryArticlesRequest),
            switchMap(
                ({
                    query,
                    filters,
                    pageSize,
                    pageIndex,
                    searchQuery,
                    sortingOptions,
                }: {
                    query: string;
                    filters: ExecutionFilters;
                    pageSize: number;
                    pageIndex: number;
                    searchQuery: string;
                    sortingOptions: SortingOptions;
                }) =>
                    this.articlesService
                        .downloadQueryArticles(
                            query,
                            filters,
                            searchQuery,
                            sortingOptions,
                            pageSize,
                            pageIndex,
                        )
                        .pipe(
                            map((blob: Blob) => actions.downloadQueryArticlesSuccess({ blob })),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.downloadQueryArticlesFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getArticlesInfo$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getArticlesInfoRequest),
            switchMap(
                ({
                    ids,
                    isSelected,
                    discovery,
                    bookmarkIds,
                }: {
                    ids: [string, string, string?][];
                    isSelected: boolean;
                    discovery: Discovery;
                    bookmarkIds: BookmarkIds;
                }) =>
                    (bookmarkIds
                        ? this.bookmarksService.getArticlesInfo(bookmarkIds, ids)
                        : this.articlesService.getArticlesInfo(ids)
                    ).pipe(
                        map((articlesInfo: ArticleInfo[]) =>
                            actions.getArticlesInfoSuccess({
                                ids,
                                articlesInfo,
                                isSelected,
                                discovery,
                            }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(
                                actions.getArticlesInfoFailure({
                                    errorResponse,
                                    entity: 'articles-info',
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    getArticles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getArticlesRequest),
            switchMap(
                ({
                    ids,
                    filters,
                    pageSize,
                    pageIndex,
                    searchQuery,
                    sortingOptions,
                    areResultsHighlighted,
                    effectsModel,
                    bookmarkIds,
                }: {
                    ids: [string, string, string?];
                    filters: ExecutionFilters;
                    pageSize: number;
                    pageIndex: number;
                    searchQuery: string;
                    sortingOptions: SortingOptions;
                    areResultsHighlighted: boolean;
                    effectsModel: EffectsModel;
                    bookmarkIds: BookmarkIds;
                }) =>
                    (bookmarkIds
                        ? this.bookmarksService.getArticles(
                              bookmarkIds,
                              ids,
                              filters,
                              pageSize,
                              pageIndex,
                              sortingOptions,
                              areResultsHighlighted,
                              searchQuery,
                          )
                        : this.articlesService.getArticles(
                              ids,
                              filters,
                              pageSize,
                              pageIndex,
                              sortingOptions,
                              areResultsHighlighted,
                              searchQuery,
                              effectsModel,
                          )
                    ).pipe(
                        map((paginatedArticles: PaginatedArticles) =>
                            actions.getArticlesSuccess({ paginatedArticles }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.getArticlesFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );

    downloadArticles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.downloadArticlesRequest),
            switchMap(
                ({
                    ids,
                    filters,
                    pageSize,
                    pageIndex,
                    searchQuery,
                    sortingOptions,
                    effectsModel,
                    bookmarkIds,
                }: {
                    ids: [string, string, string?];
                    filters: ExecutionFilters;
                    pageSize: number;
                    pageIndex: number;
                    searchQuery: string;
                    sortingOptions: SortingOptions;
                    effectsModel: EffectsModel;
                    bookmarkIds: BookmarkIds;
                }) =>
                    (bookmarkIds
                        ? this.bookmarksService.downloadArticles(
                              bookmarkIds,
                              ids,
                              filters,
                              searchQuery,
                              sortingOptions,
                              pageSize,
                              pageIndex,
                          )
                        : this.articlesService.downloadArticles(
                              ids,
                              filters,
                              searchQuery,
                              sortingOptions,
                              pageSize,
                              pageIndex,
                              effectsModel,
                          )
                    ).pipe(
                        map((blob: Blob) => actions.downloadArticlesSuccess({ blob })),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.downloadArticlesFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );

    getTermArticles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getTermArticlesRequest),
            switchMap(
                ({
                    id,
                    pageSize,
                    pageIndex,
                    filters,
                    sortingOptions,
                    searchQuery,
                    areResultsHighlighted,
                    bookmarkIds,
                }: {
                    id: string;
                    pageSize: number;
                    pageIndex: number;
                    filters: ExecutionFilters;
                    sortingOptions: SortingOptions;
                    searchQuery: string;
                    areResultsHighlighted: boolean;
                    bookmarkIds: BookmarkIds;
                }) =>
                    (bookmarkIds
                        ? this.bookmarksService.getArticles(
                              bookmarkIds,
                              [id, id],
                              filters,
                              pageSize,
                              pageIndex,
                              sortingOptions,
                              areResultsHighlighted,
                              searchQuery,
                          )
                        : this.articlesService.getArticles(
                              [id, id],
                              filters,
                              pageSize,
                              pageIndex,
                              sortingOptions,
                              areResultsHighlighted,
                              searchQuery,
                          )
                    ).pipe(
                        map((paginatedTermArticles: PaginatedArticles) =>
                            actions.getTermArticlesSuccess({ paginatedTermArticles }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.getTermArticlesFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );

    downloadTermArticles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.downloadTermArticlesRequest),
            switchMap(
                ({
                    id,
                    pageSize,
                    pageIndex,
                    filters,
                    searchQuery,
                    sortingOptions,
                    bookmarkIds,
                }: {
                    id: string;
                    pageSize: number;
                    pageIndex: number;
                    filters: ExecutionFilters;
                    searchQuery: string;
                    sortingOptions: SortingOptions;
                    bookmarkIds: BookmarkIds;
                }) =>
                    (bookmarkIds
                        ? this.bookmarksService.downloadArticles(
                              bookmarkIds,
                              [id, id],
                              filters,
                              searchQuery,
                              sortingOptions,
                              pageSize,
                              pageIndex,
                          )
                        : this.articlesService.downloadArticles(
                              [id, id],
                              filters,
                              searchQuery,
                              sortingOptions,
                              pageSize,
                              pageIndex,
                          )
                    ).pipe(
                        map((blob: Blob) => actions.downloadTermArticlesSuccess({ blob })),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.downloadTermArticlesFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );

    getArticle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getArticleRequest),
            switchMap(
                ({
                    id,
                    effectsModel,
                    associationIds,
                }: {
                    id: string;
                    effectsModel: EffectsModel;
                    associationIds: string[];
                }) =>
                    this.articlesService.getArticle(id, effectsModel, associationIds).pipe(
                        map((article: Article) => actions.getArticleSuccess({ article })),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(actions.getArticleFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );

    downloadFullArticle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.downloadFullArticleRequest),
            switchMap(({ id, associationIds }: { id: string; associationIds?: string[] }) =>
                this.articlesService.downloadFullArticle(id, associationIds).pipe(
                    map((blob: Blob) => actions.downloadFullArticleSuccess({ blob })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.downloadFullArticleFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    downloadSelectedArticles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.downloadSelectedArticlesRequest),
            switchMap(
                ({
                    ids,
                    extension,
                    filters,
                    searchQuery,
                    query,
                    associationIds,
                }: {
                    ids: string[];
                    extension: FileExtension;
                    filters: ExecutionFilters;
                    searchQuery: string;
                    query: string;
                    associationIds?: string[];
                }) =>
                    this.articlesService
                        .downloadSelectedArticles(
                            ids,
                            extension,
                            filters,
                            searchQuery,
                            query,
                            associationIds,
                        )
                        .pipe(
                            map((blob: Blob) => actions.downloadSelectedArticlesSuccess({ blob })),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.downloadSelectedArticlesFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getArticleBibTex$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getBibTexRequest),
            switchMap(({ id }: { id: string }) =>
                this.articlesService.getBibTex(id).pipe(
                    map((bibTex: string) => actions.getBibTexSuccess({ bibTex })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getBibTexFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    downloadBibTex$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.downloadBibTexRequest),
            switchMap(({ id }: { id: string }) =>
                this.articlesService.downloadBibTex(id).pipe(
                    map((blob: Blob) => actions.downloadBibTexSuccess({ blob })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.downloadBibTexFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
