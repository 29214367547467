/** third-party imports */
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';

/** custom imports */
import { CowMilkProfilerService } from './services/cow-milk-profiler.service';
import { IngredientProfilerService } from '../ingredient-profiler/services/ingredient-profiler.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as actions from './cow-milk-profiler.actions';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';
import ProfilerSearch from '@apps/leap/src/app/shared/enums/profiler-search.enum';
import PaginatedInsights from '../ingredient-profiler/interfaces/paginated-insights.interface';
import RelationshipGroup from '../ingredient-profiler/enums/relationship-group.enum';
import Overview from '../ingredient-profiler/interfaces/overview.interface';
import HealthLabelStatistics from '../ingredient-profiler/interfaces/health-label-statistics.interface';
import HealthLabelSummaries from '../ingredient-profiler/interfaces/health-label-summaries.interface';
import HealthLabelTopCompounds from '../ingredient-profiler/interfaces/health-label-top-compounds.interface';

@Injectable()
export class CowMilkProfilerEffects {
    constructor(
        private actions$: Actions,
        private cowMilkProfilerService: CowMilkProfilerService,
        private ingredientProfilerService: IngredientProfilerService,
    ) {}

    getInsights$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getInsightsRequest),
            mergeMap(
                ({
                    compoundId,
                    filters,
                    pageSize,
                    pageIndex,
                    preferences,
                    sortingOptions,
                }: {
                    compoundId: string;
                    filters: ExecutionFilters;
                    pageSize?: number;
                    pageIndex?: number;
                    preferences: UserPreferences;
                    sortingOptions?: SortingOptions;
                }) =>
                    // when the ingredient id is undefined the BE uses the cow milk id
                    this.ingredientProfilerService
                        .getInsights(
                            undefined,
                            compoundId,
                            filters,
                            pageSize,
                            pageIndex,
                            preferences,
                            sortingOptions,
                        )
                        .pipe(
                            map((paginatedInsights: PaginatedInsights) =>
                                actions.getInsightsSuccess({
                                    compoundId,
                                    paginatedInsights,
                                }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getInsightsFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getTargetInsights$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getTargetInsightsRequest),
            mergeMap(
                ({
                    targetId,
                    filters,
                    pageSize,
                    pageIndex,
                    preferences,
                }: {
                    targetId: string;
                    filters: ExecutionFilters;
                    pageSize?: number;
                    pageIndex?: number;
                    preferences: UserPreferences;
                }) =>
                    this.ingredientProfilerService
                        .getTargetInsights(
                            undefined,
                            targetId,
                            filters,
                            pageSize,
                            pageIndex,
                            preferences,
                        )
                        .pipe(
                            map((paginatedInsights: PaginatedInsights) =>
                                actions.getTargetInsightsSuccess({
                                    paginatedInsights,
                                }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getTargetInsightsFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    downloadInsights$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.downloadInsightsRequest),
            switchMap(
                ({
                    filters,
                    sortingOptions,
                    preferences,
                }: {
                    filters: ExecutionFilters;
                    sortingOptions: SortingOptions;
                    preferences: UserPreferences;
                }) =>
                    // when the ingredient id is undefined the BE uses the cow milk id
                    this.ingredientProfilerService
                        .downloadInsights(undefined, filters, sortingOptions, preferences)
                        .pipe(
                            map((blob: Blob) => actions.downloadInsightsSuccess({ blob })),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.downloadInsightsFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getOverview$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getOverviewRequest),
            switchMap(
                ({
                    countBy,
                    filters,
                    preferences,
                }: {
                    countBy: string;
                    filters: ExecutionFilters;
                    preferences: UserPreferences;
                }) =>
                    // when the ingredient id is undefined the BE uses the cow milk id
                    this.ingredientProfilerService
                        .getOverview(undefined, countBy, filters, preferences)
                        .pipe(
                            map((overview: Overview) => actions.getOverviewSuccess({ overview })),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getOverviewFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getHealthLabelsStatistics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getHealthLabelsStatisticsRequest),
            switchMap(
                ({
                    filters,
                    preferences,
                    areEffectsEnabled,
                }: {
                    filters: ExecutionFilters;
                    preferences: UserPreferences;
                    areEffectsEnabled: boolean;
                }) =>
                    // when the ingredient id is undefined the BE uses the cow milk id
                    this.ingredientProfilerService
                        .getHealthLabelsStatistics(
                            undefined,
                            filters,
                            preferences,
                            areEffectsEnabled,
                        )
                        .pipe(
                            map(
                                ({
                                    healthLabelsStatistics,
                                    relationshipsPerGroup,
                                }: {
                                    healthLabelsStatistics: HealthLabelStatistics[];
                                    relationshipsPerGroup: Record<RelationshipGroup, string[]>;
                                }) =>
                                    actions.getHealthLabelsStatisticsSuccess({
                                        healthLabelsStatistics,
                                        relationshipsPerGroup,
                                    }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getHealthLabelsStatisticsFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getHealthLabelSummaries$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getHealthLabelSummariesRequest),
            mergeMap(
                ({
                    healthLabel,
                    filters,
                    preferences,
                    areEffectsEnabled,
                }: {
                    healthLabel: string;
                    filters: ExecutionFilters;
                    preferences: UserPreferences;
                    areEffectsEnabled: boolean;
                }) =>
                    // when the ingredient id is undefined the BE uses the cow milk id
                    this.ingredientProfilerService
                        .getHealthLabelSummaries(
                            undefined,
                            healthLabel,
                            filters,
                            preferences,
                            areEffectsEnabled,
                        )
                        .pipe(
                            map((healthLabelSummaries: HealthLabelSummaries) =>
                                actions.getHealthLabelSummariesSuccess({ healthLabelSummaries }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getHealthLabelSummariesFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getHealthLabelTopCompounds$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getHealthLabelTopCompoundsRequest),
            mergeMap(
                ({
                    healthLabel,
                    filters,
                    preferences,
                    areEffectsEnabled,
                }: {
                    healthLabel: string;
                    filters: ExecutionFilters;
                    preferences: UserPreferences;
                    areEffectsEnabled: boolean;
                }) =>
                    // when the ingredient id is undefined the BE uses the cow milk id
                    this.ingredientProfilerService
                        .getHealthLabelTopCompounds(
                            undefined,
                            healthLabel,
                            filters,
                            preferences,
                            areEffectsEnabled,
                        )
                        .pipe(
                            map((healthLabelTopCompounds: HealthLabelTopCompounds) =>
                                actions.getHealthLabelTopCompoundsSuccess({
                                    healthLabelTopCompounds,
                                }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(actions.getHealthLabelTopCompoundsFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );

    getSuggestions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getSearchSuggestionsRequest),
            switchMap(({ query, activeSearch }: { query: string; activeSearch: ProfilerSearch }) =>
                this.cowMilkProfilerService.getSearchSuggestions(query, activeSearch).pipe(
                    map((suggestionIds: string[]) =>
                        actions.getSearchSuggestionsSuccess({ suggestionIds }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getSearchSuggestionsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
