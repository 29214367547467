/** third-party imports */
import { Injectable } from '@angular/core';
import { flatten, intersection, uniq } from 'lodash';

/** custom imports */
import { ArrayHandlerService } from '@leap-common/services/array-handler.service';
import { FormatterService } from '@leap-common/services/formatter.service';
import { InsightParser } from '@apps/leap/src/app/shared/parsers/insight.parser';
import { DiscoveryParser } from '@apps/leap/src/app/shared/parsers/discovery.parser';
import { OpenDiscoveryParser } from '../../discovery/open/parsers/open-discovery.parser';
import { ClosedDiscoveryParser } from '../../discovery/closed/parsers/closed-discovery.parser';
import { ArticlesParser } from '../../articles/parsers/articles.parser';

/** Helpers */
import {
    isNewerOrSameVersion,
    isTruthy,
    mapEmailToUsername,
    pluralize,
} from '@leap-common/utilities/helpers';

/** Constants */
import { COMMA_DELIMITER } from '@leap-common/constants/delimiters';
import {
    CLOSED_DISCOVERY_SOURCE_TARGET_ARTICLES_UKG_VERSION,
    CREATE_BOOKMARK_SUCCESS_MESSAGE,
    CREATE_BOOKMARKS_ERROR_MESSAGE,
    CREATE_BOOKMARKS_EXISTING_SUCCESS_MESSAGE,
    CREATE_BOOKMARKS_SUCCESS_MESSAGE,
    LEGACY_UKG_VERSION,
    USER_UPLOADED_ORIGIN_TYPE,
} from '../constants/bookmarks';
import { BOOKMARK } from '@apps/leap/src/app/shared/constants/bookmarks';

/** Interfaces - Types - Enums */
import PaginatedBookmarksRestApi from '../rest-api-interfaces/paginated-bookmarks.rest.interface';
import PaginatedBookmarks from '../interfaces/paginated-bookmarks.interface';
import Bookmark from '../interfaces/bookmark.interface';
import BookmarkData from '../types/bookmark-data.type';
import BookmarkType from '../enums/bookmark.enum';
import BookmarkRestApi from '../rest-api-interfaces/bookmark.rest.interface';
import BookmarkConfigurationRestApi from '../rest-api-types/configuration.rest.type';
import CreatedArticleBookmarksRestApi from '../rest-api-types/created-article-bookmarks.rest.type';
import Entity from '../interfaces/entity.interface';
import ArticleRestApi from '../../articles/rest-api-interfaces/article.rest.interface';
import OpenDiscoveryInsightRestApi from '../../discovery/open/rest-api-interfaces/insight.rest.interface';
import ClosedDiscoveryInsightRestApi from '../../discovery/closed/rest-api-interfaces/insight.rest.interface';
import EntityRestApi from '../rest-api-interfaces/entity.rest.interface';
import Discovery from '@apps/leap/src/app/shared/enums/discovery.enum';
import BookmarkOrigin from '../interfaces/origin.interface';
import BookmarkOriginType from '../enums/origin.enum';
import BookmarkConfiguration from '../interfaces/configuration.interface';
import BookmarkConfigurationItemRestApi from '../rest-api-interfaces/configuration-item.rest.interface';
import InsightFilterCounts from '@apps/leap/src/app/shared/modules/insight-filters/interfaces/counts.interface';
import InsightFilterCountsRestApi from '@apps/leap/src/app/shared/modules/insight-filters/rest-api-interfaces/counts.rest.interface';
import ArticleFilterCounts from '../../articles/interfaces/filter-counts.interface';
import ArticleFilterCountsRestApi from '@leap-store/core/src/lib/data/articles/rest-api-interfaces/filter-counts.rest.interface';
import Project from '@leap-store/core/src/lib/data/projects/interfaces/project.interface';
import Alert from '@leap-store/core/src/lib/ui/alerts/interfaces/alert.interface';
import AlertType from '@leap-store/core/src/lib/ui/alerts/enums/alert-type.enum';

@Injectable()
export class BookmarksParser {
    constructor(
        private arrayHandlerService: ArrayHandlerService,
        private formatterService: FormatterService,
        private insightParser: InsightParser,
        private discoveryParser: DiscoveryParser,
        private openDiscoveryInsightsParser: OpenDiscoveryParser,
        private closedDiscoveryInsightsParser: ClosedDiscoveryParser,
        private articlesParser: ArticlesParser,
    ) {}

    parsePaginatedResults(
        paginatedBookmarks: PaginatedBookmarksRestApi,
        type: BookmarkType,
        studyTypesOrder: string[],
    ): PaginatedBookmarks {
        return {
            results: paginatedBookmarks.results
                ? this.parseBookmarks(paginatedBookmarks.results, type, studyTypesOrder)
                : [],
            filterCounts: this.parseFilterCounts(
                paginatedBookmarks.counts,
                type,
                paginatedBookmarks.full_text_total,
            ),
            pageIndex: paginatedBookmarks.pageIndex,
            pageSize: paginatedBookmarks.pageSize,
            total: paginatedBookmarks.total,
            preferences: paginatedBookmarks.preferences,
            dateRange: [
                paginatedBookmarks.oldest_publication_year,
                paginatedBookmarks.newest_publication_year,
            ],
            cowMilkConcentrationRange: [
                paginatedBookmarks.smallestCowMilkConcentration,
                paginatedBookmarks.largestCowMilkConcentration,
            ],
            moleculeWeightRange: [
                paginatedBookmarks.smallestMolecularWeight,
                paginatedBookmarks.largestMolecularWeight,
            ],
        };
    }

    parseBookmarks(
        bookmarks: BookmarkRestApi[],
        type: BookmarkType,
        studyTypesOrder: string[],
    ): Bookmark[] {
        return bookmarks.map((bookmark: BookmarkRestApi) =>
            this.parseBookmark(bookmark, type, studyTypesOrder),
        );
    }

    parseBookmark(
        bookmark: BookmarkRestApi,
        type: BookmarkType,
        studyTypesOrder: string[],
    ): Bookmark {
        const parsedBookmarkData: BookmarkData =
            type === BookmarkType.entity
                ? this.parseEntity(bookmark.data as EntityRestApi)
                : type === BookmarkType.associationOpen
                ? this.openDiscoveryInsightsParser.parseInsight(
                      bookmark.data as OpenDiscoveryInsightRestApi,
                  )
                : type === BookmarkType.associationClosed
                ? this.closedDiscoveryInsightsParser.parseInsight(
                      bookmark.data as ClosedDiscoveryInsightRestApi,
                      studyTypesOrder,
                  )
                : type === BookmarkType.article
                ? this.articlesParser.parseArticle(bookmark.data as ArticleRestApi)
                : undefined;
        const isUserUploaded: boolean = bookmark.origin?.type === USER_UPLOADED_ORIGIN_TYPE;
        const isLegacy: boolean = bookmark.ukg_version === LEGACY_UKG_VERSION;

        return {
            data: parsedBookmarkData,
            id: bookmark.uuid,
            createdBy: bookmark.creator_mail
                ? mapEmailToUsername(bookmark.creator_mail)
                : undefined,
            createdAt: bookmark.created_at,
            updatedAt: bookmark.updated_at,
            configuration: this.parseConfiguration(bookmark.configuration, type),
            notes: bookmark.note,
            isUserUploaded,
            isLegacy,
            areAssociationClosedSourceTargetArticlesEnabled: isNewerOrSameVersion(
                bookmark.ukg_version,
                CLOSED_DISCOVERY_SOURCE_TARGET_ARTICLES_UKG_VERSION,
            ),
        };
    }

    parseEntity(entity: EntityRestApi): Entity {
        return {
            type: BookmarkType.entity,
            id: entity.uid,
            name: entity.name,
            categories: this.arrayHandlerService.sortObjectByValues(entity.categories) || {},
            subcategories: this.insightParser.parseSubcategories(entity.categories),
            synonyms: entity.synonyms || [],
            definitions: entity.definitions || {},
            tags: this.insightParser.parseTags(entity.tags?.Tags),
            healthLabels: this.insightParser.parseHealthLabels(entity.tags?.['Health areas']),
            molecules: this.insightParser.parseMolecules(entity.tags?.['Molecule classification']),
            profileCategory: this.insightParser.parseProfileCategories(
                entity.tags?.['Uniqueness profile'],
            )?.[0],
            prevalence: undefined,
            labs: this.insightParser.parseLabs(entity.tags?.['UCD DMD lab']),
            patentsCount: entity.noOfPatents,
            moleculeWeight: entity.molecularWeight,
            cowMilkConcentration: entity.cowMilkConcentration,
        };
    }

    parseConfiguration(
        configuration: BookmarkConfigurationRestApi,
        type: BookmarkType,
    ): BookmarkConfiguration {
        // parsing for the other cases can be added if needed
        if (type === BookmarkType.article) {
            const sourceItem: BookmarkConfigurationItemRestApi =
                configuration.source as BookmarkConfigurationItemRestApi;
            const intermediateItem: BookmarkConfigurationItemRestApi =
                configuration.intermediate as BookmarkConfigurationItemRestApi;
            const targetItem: BookmarkConfigurationItemRestApi =
                configuration.target as BookmarkConfigurationItemRestApi;
            const query: string = (configuration.query || configuration.question) as string;

            return {
                type,
                id: configuration.external_id as string,
                sourceId: sourceItem?.uid,
                sourceName: sourceItem?.name,
                intermediateId: intermediateItem?.uid,
                intermediateName: intermediateItem?.name,
                targetId: targetItem?.uid,
                targetName: targetItem?.name,
                query,
            };
        }
    }

    parseFilterCounts(
        counts: InsightFilterCountsRestApi | ArticleFilterCountsRestApi,
        type: BookmarkType,
        fullTextTotal?: number,
    ): InsightFilterCounts | ArticleFilterCounts {
        return type === BookmarkType.entity ||
            type === BookmarkType.associationOpen ||
            type === BookmarkType.associationClosed
            ? this.insightParser.parseFilterCounts(counts as InsightFilterCountsRestApi)
            : type === BookmarkType.article
            ? this.articlesParser.parseFilterCounts(
                  counts as ArticleFilterCountsRestApi,
                  fullTextTotal,
              )
            : null;
    }

    getCreatedBookmarksAlert(projects: Project[]): Alert {
        const projectNames: string = projects
            .map(({ name }: Project) => name)
            .join(COMMA_DELIMITER);
        const message: string = this.formatterService.formatString(
            CREATE_BOOKMARK_SUCCESS_MESSAGE,
            projectNames,
        );

        return {
            type: AlertType.success,
            messages: [message],
            icon: 'check',
        };
    }

    getCreatedArticleBookmarksAlert(
        createdArticles: CreatedArticleBookmarksRestApi[],
        projects: Project[],
        bookmarksCount: number,
    ): Alert {
        const createdBookmarksCount: number =
            this.parseCreatedArticleBookmarksCount(createdArticles);
        const projectNames: string = projects
            .map(({ name }: Project) => name)
            .join(COMMA_DELIMITER);
        const existingBookmarksCount: number = this.parseExistingArticleBookmarksCount(
            createdArticles,
            bookmarksCount,
        );
        const message: string =
            createdBookmarksCount > 0
                ? bookmarksCount === 1
                    ? this.formatterService.formatString(
                          CREATE_BOOKMARK_SUCCESS_MESSAGE,
                          projectNames,
                      )
                    : existingBookmarksCount > 0
                    ? this.formatterService.formatString(
                          CREATE_BOOKMARKS_EXISTING_SUCCESS_MESSAGE,
                          createdBookmarksCount,
                          pluralize(BOOKMARK.toLowerCase(), createdBookmarksCount),
                          projectNames,
                          existingBookmarksCount,
                          pluralize(BOOKMARK.toLowerCase(), createdBookmarksCount),
                      )
                    : this.formatterService.formatString(
                          CREATE_BOOKMARKS_SUCCESS_MESSAGE,
                          createdBookmarksCount,
                          pluralize(BOOKMARK.toLowerCase(), createdBookmarksCount),
                          projectNames,
                      )
                : this.formatterService.formatString(
                      CREATE_BOOKMARKS_ERROR_MESSAGE,
                      pluralize('project', projects.length),
                  );

        return createdBookmarksCount > 0
            ? {
                  type: AlertType.success,
                  messages: [message],
                  icon: 'check',
              }
            : {
                  type: AlertType.error,
                  messages: [message],
              };
    }

    parseCreatedArticleBookmarksCount(createdArticles: CreatedArticleBookmarksRestApi[]): number {
        return uniq(
            flatten(
                flatten(
                    createdArticles.map(({ articles }: CreatedArticleBookmarksRestApi) => articles),
                ).map(
                    ({ configuration }: { configuration: BookmarkConfigurationRestApi }) =>
                        configuration?.external_id,
                ),
            ),
        ).filter(isTruthy).length;
    }

    /**
     * Returns the number of article bookmarks that already existed in at least one project.
     *
     * Example:
     * - Project 1 has bookmarks A, B, C.
     * - Project 2 has bookmark A.
     * - User tries to add bookmarks A, B and D to Project 1 and Project 2.
     * - The createdArticles parameter will be of the form [[D], [B, D]].
     * - The value of the auxiliary constant notExistingBookmarksCount is 1 (D, the intersection
     *   of the createdArticles arrays).
     * - The return value is 2 (A and B).
     */
    parseExistingArticleBookmarksCount(
        createdArticles: CreatedArticleBookmarksRestApi[],
        bookmarksCount: number,
    ): number {
        const externalIds: string[][] = createdArticles.map(
            ({ articles }: CreatedArticleBookmarksRestApi) =>
                articles.map(
                    ({ configuration }: { configuration: BookmarkConfigurationRestApi }) =>
                        configuration.external_id as string,
                ),
        );
        // the number of bookmarks that did not exist in any of the projects
        const notExistingBookmarksCount: number = intersection(...externalIds).length;

        return bookmarksCount - notExistingBookmarksCount;
    }

    serializeBookmarkType(bookmarkType: BookmarkType, isPlural?: boolean): string {
        return bookmarkType === BookmarkType.entity
            ? isPlural
                ? 'entities'
                : 'entity'
            : bookmarkType === BookmarkType.associationOpen ||
              bookmarkType === BookmarkType.associationClosed
            ? isPlural
                ? 'associations'
                : 'association'
            : bookmarkType === BookmarkType.article
            ? isPlural
                ? 'articles'
                : 'article'
            : undefined;
    }

    serializeOrigin(origin: BookmarkOrigin): BookmarkConfigurationRestApi {
        return origin
            ? {
                  type: this.serializeOriginType(origin.type),
                  source: this.serializeConfigurationItem(origin.sourceId, origin.sourceName),
                  target: this.serializeConfigurationItem(origin.targetId, origin.targetName),
              }
            : null;
    }

    serializeOriginType(type: BookmarkOriginType): string {
        return type === BookmarkOriginType.openDiscovery
            ? this.discoveryParser.serializeDiscovery(Discovery.open)
            : type === BookmarkOriginType.closedDiscovery
            ? this.discoveryParser.serializeDiscovery(Discovery.closed)
            : type === BookmarkOriginType.articlesBrowser
            ? BookmarkOriginType.articlesBrowser
            : type === BookmarkOriginType.conversationalSearch
            ? BookmarkOriginType.conversationalSearch
            : undefined;
    }

    serializeConfigurations(
        configurations: BookmarkConfiguration[],
        origin?: BookmarkOrigin,
    ): BookmarkConfigurationRestApi {
        return configurations[0].type === BookmarkType.article
            ? {
                  ...this.serializeConfiguration(configurations[0], origin),
                  external_id: undefined,
                  external_ids: configurations.map(({ id }: BookmarkConfiguration) => id),
              }
            : configurations.length === 1
            ? this.serializeConfiguration(configurations[0])
            : null;
    }

    serializeConfiguration(
        configuration: BookmarkConfiguration,
        origin?: BookmarkOrigin,
    ): BookmarkConfigurationRestApi {
        return configuration.type === BookmarkType.entity
            ? {
                  entity: this.serializeConfigurationItem(configuration.id, configuration.name),
              }
            : configuration.type === BookmarkType.associationOpen
            ? {
                  type: this.discoveryParser.serializeDiscovery(Discovery.open),
                  source: this.serializeConfigurationItem(
                      configuration.sourceId,
                      configuration.sourceName,
                  ),
                  target: this.serializeConfigurationItem(
                      configuration.targetId,
                      configuration.targetName,
                  ),
              }
            : configuration.type === BookmarkType.associationClosed
            ? {
                  type: this.discoveryParser.serializeDiscovery(Discovery.closed),
                  source: this.serializeConfigurationItem(
                      configuration.sourceId,
                      configuration.sourceName,
                  ),
                  intermediate: this.serializeConfigurationItem(
                      configuration.intermediateId,
                      configuration.intermediateName,
                  ),
                  target: this.serializeConfigurationItem(
                      configuration.targetId,
                      configuration.targetName,
                  ),
              }
            : configuration.type === BookmarkType.article
            ? {
                  external_id: configuration.id,
                  source: this.serializeConfigurationItem(
                      configuration.sourceId,
                      configuration.sourceName,
                  ),
                  intermediate: configuration.intermediateId
                      ? this.serializeConfigurationItem(
                            configuration.intermediateId,
                            configuration.intermediateName,
                        )
                      : undefined,
                  target: this.serializeConfigurationItem(
                      configuration.targetId,
                      configuration.targetName,
                  ),
                  ...(origin?.type === BookmarkOriginType.articlesBrowser && {
                      query: origin.query,
                  }),
                  ...(origin?.type === BookmarkOriginType.conversationalSearch && {
                      question: origin?.query,
                      chat_uuid: origin?.queryId,
                      synthesized_confidence_score:
                          origin?.confidenceScores?.synthesized_confidence_score,
                      rag_confidence_score: origin?.confidenceScores?.rag_confidence_score,
                  }),
              }
            : null;
    }

    serializeConfigurationItem(id: string, name: string): BookmarkConfigurationItemRestApi {
        return id
            ? {
                  uid: id,
                  name,
              }
            : undefined;
    }
}
