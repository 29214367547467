import { Component, HostBinding, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '@environments/leap/environment';

/** Constants */
import {
    TERMS_OF_USE_HEADER,
    TERMS_OF_USE_INFORMATION_ICON,
    TERMS_OF_USE_INFORMATION_TEXT,
    TERMS_OF_USE_PATH,
    TERMS_OF_USE_LOADING_TEXT,
} from '../../../constants/legal';

/** Facades */
import { LayoutFacade } from '@leap-store/core/src/lib/ui/layout/layout.facade';

/** Interfaces */
import Header from '@leap-common/interfaces/header.interface';

@Component({
    selector: 'app-terms-of-use',
    templateUrl: 'terms-of-use.container.component.html',
    styleUrls: ['terms-of-use.container.component.scss'],
})
export class TermsOfUseContainerComponent implements OnInit, OnDestroy {
    @HostBinding('class') classes = 'content-wrapper';

    isInformationDisplayed: boolean;

    // subscriptions
    queryParamsSubscription: Subscription;

    readonly header: Header = TERMS_OF_USE_HEADER;
    readonly informationIcon: string = TERMS_OF_USE_INFORMATION_ICON;
    readonly informationText: string = TERMS_OF_USE_INFORMATION_TEXT;
    readonly path: string = TERMS_OF_USE_PATH;
    readonly loadingText: string = TERMS_OF_USE_LOADING_TEXT;

    constructor(private activatedRoute: ActivatedRoute, private uiFacade: LayoutFacade) {}

    ngOnInit(): void {
        this.subscribeToQueryParams();
    }

    ngOnDestroy(): void {
        this.queryParamsSubscription?.unsubscribe();
    }

    subscribeToQueryParams(): void {
        this.queryParamsSubscription = this.activatedRoute.queryParams.subscribe(
            (params: Params) => {
                this.isInformationDisplayed = params?.shouldAcceptTerms === 'true';
            },
        );
    }

    onIframeScrolledToBottom(isScrolledToBottom: boolean): void {
        this.uiFacade.updateTermsOfUseAcceptanceStatus(isScrolledToBottom);
    }
}
