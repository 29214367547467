import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment, featureFlags } from '@environments/leap/environment';

/** Constants */
import {
    LAYOUT_LANDING_PATH,
    DISCOVERY_PATH,
    CONVERSATIONAL_SEARCH_PATH,
    INGREDIENT_PROFILER_PATH,
    NOTEBOOKS_PATH,
    PROJECTS_PATH,
    ARTICLES_PATH,
    COW_MILK_PROFILER_PATH,
    DAIRY_PROFILER_PATH,
    INGREDIENT_VIEWER_PATH,
    INGREDIENT_COMPARISON_PATH,
    INGREDIENT_MANAGEMENT_PATH,
    HELP_CENTER_PATH,
    USER_MANAGEMENT_PATH,
    USER_SETTINGS_PATH,
    EMPTY_ROUTE_PATH,
} from './constants/menu-items';

/** Guards */
import { RoleGuard } from '../auth/core/guards/role.guard';
import { FeatureGuard } from '@leap-libs/feature-flags/src/lib/feature.guard';

/** Components */
import { LayoutContainerComponent } from './components/containers/layout/layout.container.component';
import { EmptyRouteContainerComponent } from './components/containers/empty-route/empty-route.container.component';
import { LandingContainerComponent } from './components/containers/landing/landing.container.component';

// apps
const isIngredientProfilerApp: boolean = environment.app === 'ingpro';
const isIngredientAnalyzerApp: boolean = environment.app === 'dia';
// features
const isLayoutLandingEnabled: boolean = featureFlags.layoutLanding;
const isConversationalSearchEnabled: boolean = featureFlags.conversationalSearch; // TODO: Find a way to use isFeatureEnabled() instead

const fallbackPath: string = isIngredientProfilerApp
    ? INGREDIENT_PROFILER_PATH
    : isLayoutLandingEnabled
    ? LAYOUT_LANDING_PATH
    : isConversationalSearchEnabled
    ? CONVERSATIONAL_SEARCH_PATH
    : DISCOVERY_PATH;

const routes: Routes = [
    {
        path: '',
        component: LayoutContainerComponent,
        children: [
            {
                path: LAYOUT_LANDING_PATH,
                canActivate: [FeatureGuard],
                data: {
                    feature: 'layoutLanding',
                    path: fallbackPath,
                    shouldApplyGuard: !isLayoutLandingEnabled,
                },
                component: LandingContainerComponent,
            },
            {
                path: DISCOVERY_PATH,
                canActivate: [FeatureGuard],
                data: {
                    feature: 'discovery',
                    path: isIngredientProfilerApp ? INGREDIENT_PROFILER_PATH : LAYOUT_LANDING_PATH,
                    shouldApplyGuard: isIngredientProfilerApp || isIngredientAnalyzerApp,
                },
                loadChildren: () =>
                    import('../../discovery/app.module').then((module) => module.AppModule),
            },
            {
                path: NOTEBOOKS_PATH,
                canActivate: [FeatureGuard],
                data: {
                    feature: 'notebooks',
                    path: fallbackPath,
                },
                loadChildren: () =>
                    import('../../notebooks/app.module').then((module) => module.AppModule),
            },
            {
                path: PROJECTS_PATH,
                canActivate: [FeatureGuard],
                data: {
                    feature: 'projects',
                    path: fallbackPath,
                },
                loadChildren: () =>
                    import('../../projects/app.module').then((module) => module.AppModule),
            },
            {
                path: CONVERSATIONAL_SEARCH_PATH,
                canActivate: [FeatureGuard],
                data: {
                    feature: 'conversationalSearch',
                    path: fallbackPath,
                },
                loadChildren: () =>
                    import('../../conversational-search/app.module').then(
                        (module) => module.AppModule,
                    ),
            },
            {
                path: ARTICLES_PATH,
                canActivate: [FeatureGuard],
                data: {
                    feature: 'articlesBrowser',
                    path: fallbackPath,
                },
                loadChildren: () =>
                    import('../../articles/app.module').then((module) => module.AppModule),
            },
            {
                path: COW_MILK_PROFILER_PATH,
                canActivate: [FeatureGuard],
                data: {
                    feature: 'cowMilkProfiler',
                    path: fallbackPath,
                },
                loadChildren: () =>
                    import('../../cow-milk-profiler/app.module').then((module) => module.AppModule),
            },
            {
                path: DAIRY_PROFILER_PATH,
                canActivate: [FeatureGuard],
                data: {
                    feature: 'dairyProfiler',
                    path: fallbackPath,
                },
                loadChildren: () =>
                    import('../../dairy-profiler/app.module').then((module) => module.AppModule),
            },
            {
                path: INGREDIENT_PROFILER_PATH,
                canActivate: [FeatureGuard],
                data: {
                    feature: 'ingredientProfiler',
                    path: DISCOVERY_PATH,
                    shouldApplyGuard: !isIngredientProfilerApp,
                },
                loadChildren: () =>
                    import('../../ingredient-profiler/app.module').then(
                        (module) => module.AppModule,
                    ),
            },
            {
                path: INGREDIENT_VIEWER_PATH,
                canActivate: [FeatureGuard],
                data: {
                    feature: 'ingredientViewer',
                    path: fallbackPath,
                },
                loadChildren: () =>
                    import('../../ingredient-viewer/app.module').then((module) => module.AppModule),
            },
            {
                path: INGREDIENT_COMPARISON_PATH,
                canActivate: [FeatureGuard],
                data: {
                    feature: 'ingredientComparison',
                    path: fallbackPath,
                },
                loadChildren: () =>
                    import('../../ingredient-comparison/app.module').then(
                        (module) => module.AppModule,
                    ),
            },
            {
                path: INGREDIENT_MANAGEMENT_PATH,
                canActivate: [FeatureGuard],
                data: {
                    feature: 'ingredientManagement',
                    path: fallbackPath,
                },
                loadChildren: () =>
                    import('../../ingredient-management/app.module').then(
                        (module) => module.AppModule,
                    ),
            },
            {
                path: HELP_CENTER_PATH,
                loadChildren: () =>
                    import('../support/support.module').then((module) => module.SupportModule),
            },
            {
                path: USER_MANAGEMENT_PATH,
                canActivate: [RoleGuard],
                loadChildren: () =>
                    import('../user-management/user-management.module').then(
                        (module) => module.UserManagementModule,
                    ),
            },
            {
                path: USER_SETTINGS_PATH,
                loadChildren: () =>
                    import('../user-settings/user-settings.module').then(
                        (module) => module.UserSettingsModule,
                    ),
            },
            {
                path: EMPTY_ROUTE_PATH,
                component: EmptyRouteContainerComponent,
            },
            { path: '', redirectTo: fallbackPath, pathMatch: 'full' },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class LayoutRoutingModule {}
