import { environment } from '@environments/leap/environment';

/** Helpers */
import { replaceDelimiter } from '@leap-common/utilities/helpers';

/** Constants */
import { LINE_BREAK, WHITESPACE } from '@leap-common/constants/common';
import {
    COW_MILK_PROFILER_ICON_ACTIVE,
    COW_MILK_PROFILER_PATH,
    COW_MILK_PROFILER_TITLE,
    DAIRY_PROFILER_ICON_ACTIVE,
    DAIRY_PROFILER_PATH,
    DAIRY_PROFILER_TITLE,
    ARTICLES_ICON_ACTIVE,
    ARTICLES_PATH,
    ARTICLES_TITLE,
    DISCOVERY_ICON_ACTIVE,
    MY_DISCOVERY_PATH,
    OPEN_DISCOVERY_TITLE,
    DISCOVERY_TITLE,
    PROJECTS_ICON_ACTIVE,
    PROJECTS_PATH,
    PROJECTS_TITLE,
    INGREDIENT_VIEWER_ICON_ACTIVE,
    INGREDIENT_VIEWER_PATH,
    INGREDIENT_VIEWER_TITLE,
    INGREDIENT_COMPARISON_ICON_ACTIVE,
    INGREDIENT_COMPARISON_PATH,
    INGREDIENT_COMPARISON_TITLE,
    INGREDIENT_MANAGEMENT_ICON_ACTIVE,
    INGREDIENT_MANAGEMENT_PATH,
    INGREDIENT_MANAGEMENT_TITLE,
    RELEASE_NOTES_ICON,
    RELEASE_NOTES_PATH,
    RELEASE_NOTES_TITLE,
    RELEASE_NOTES_DESCRIPTION,
} from './menu-items';

/** Interfaces */
import MenuItem from '@leap-libs/main-menu/src/lib/menu-item.interface';

export const SUBTITLE: string =
    environment.app === 'd3' || environment.app === 'phi' || environment.app === 'phiplant'
        ? 'What would you like to do today?'
        : 'Select one of the items below to begin your journey';

export const COW_MILK_PROFILER_DESCRIPTION_TEXT: string =
    "Click here if you want to search thousands of Cow's milk molecules, discover all of their associated health benefits, and concentrations";

export const DAIRY_PROFILER_DESCRIPTION_TEXT: string =
    'Click here if you want to search whole dairy products and compounds as cited in literature, discover all of their associated health benefits';

export const ARTICLES_DESCRIPTION_TEXT: string =
    "Click here if you want to quickly search articles on general or adjacent topics that fall outside of a search in the cow's milk and dairy profiler";
export const PHI_ARTICLES_DESCRIPTION_TEXT: string =
    'Click here to get answers directly from scientific literature. Find the most relevant articles using free-text search.';

export const DISCOVERY_DESCRIPTION_TEXT: string =
    'Click here if you want to explore all foods, organisms, compounds, and related properties and health associations';
export const PHI_DISCOVERY_DESCRIPTION_TEXT: string =
    'Click here to explore an extensive collection of foods, organisms, compounds, and their related properties and health associations.';
export const PHI_PLANT_DISCOVERY_DESCRIPTION_TEXT: string =
    'Click here to explore an extensive collection of plants, organisms, compounds, and their related properties and health associations.';

export const PROJECTS_DESCRIPTION_TEXT: string = 'Click here to access your saved projects';
export const PHI_PROJECTS_DESCRIPTION_TEXT: string =
    'Click here to access saved projects and create one-click summaries, analyses and content.';
export const PHI_PLANT_PROJECTS_DESCRIPTION_TEXT: string =
    'Click here to access saved projects and bookmarks.';

export const INGREDIENT_VIEWER_DESCRIPTION_TEXT: string =
    'View the characteristics of a single ingredient and its relation to health effects';

export const INGREDIENT_COMPARISON_DESCRIPTION_TEXT: string =
    'Compare two or more ingredients and their health effects';

export const INGREDIENT_MANAGEMENT_DESCRIPTION_TEXT: string =
    'View the ingredients list, upload and manage a new ingredient';

const D3_FEATURE_CARD_ITEMS: MenuItem[] = [
    {
        id: 'landing-cow-milk-profiler',
        icon: COW_MILK_PROFILER_ICON_ACTIVE,
        path: COW_MILK_PROFILER_PATH,
        title: COW_MILK_PROFILER_TITLE,
        description: COW_MILK_PROFILER_DESCRIPTION_TEXT,
    },
    {
        id: 'landing-dairy-profiler',
        icon: DAIRY_PROFILER_ICON_ACTIVE,
        path: DAIRY_PROFILER_PATH,
        title: DAIRY_PROFILER_TITLE,
        description: DAIRY_PROFILER_DESCRIPTION_TEXT,
    },
    {
        id: 'landing-articles',
        icon: ARTICLES_ICON_ACTIVE,
        path: ARTICLES_PATH,
        title: ARTICLES_TITLE,
        description: ARTICLES_DESCRIPTION_TEXT,
    },
    {
        id: 'landing-discovery',
        icon: DISCOVERY_ICON_ACTIVE,
        path: MY_DISCOVERY_PATH,
        title: OPEN_DISCOVERY_TITLE,
        description: DISCOVERY_DESCRIPTION_TEXT,
    },
    {
        id: 'landing-projects',
        icon: PROJECTS_ICON_ACTIVE,
        path: PROJECTS_PATH,
        title: PROJECTS_TITLE,
        description: PROJECTS_DESCRIPTION_TEXT,
    },
];

const INGREDIENT_ANALYZER_FEATURE_CARD_ITEMS: MenuItem[] = [
    {
        id: 'landing-ingredient-viewer',
        icon: INGREDIENT_VIEWER_ICON_ACTIVE,
        path: INGREDIENT_VIEWER_PATH,
        title: replaceDelimiter(INGREDIENT_VIEWER_TITLE, WHITESPACE, LINE_BREAK),
        description: INGREDIENT_VIEWER_DESCRIPTION_TEXT,
    },
    {
        id: 'landing-ingredient-comparison',
        icon: INGREDIENT_COMPARISON_ICON_ACTIVE,
        path: INGREDIENT_COMPARISON_PATH,
        title: replaceDelimiter(INGREDIENT_COMPARISON_TITLE, WHITESPACE, LINE_BREAK),
        description: INGREDIENT_COMPARISON_DESCRIPTION_TEXT,
    },
    {
        id: 'landing-ingredient-management',
        icon: INGREDIENT_MANAGEMENT_ICON_ACTIVE,
        path: INGREDIENT_MANAGEMENT_PATH,
        title: replaceDelimiter(INGREDIENT_MANAGEMENT_TITLE, WHITESPACE, LINE_BREAK),
        description: INGREDIENT_MANAGEMENT_DESCRIPTION_TEXT,
    },
];

const PHI_FEATURE_CARD_ITEMS: MenuItem[] = [
    {
        id: 'landing-discovery',
        icon: DISCOVERY_ICON_ACTIVE,
        path: MY_DISCOVERY_PATH,
        title: DISCOVERY_TITLE,
        description: PHI_DISCOVERY_DESCRIPTION_TEXT,
    },
    {
        id: 'landing-articles',
        icon: ARTICLES_ICON_ACTIVE,
        path: ARTICLES_PATH,
        title: ARTICLES_TITLE,
        description: PHI_ARTICLES_DESCRIPTION_TEXT,
    },
    {
        id: 'landing-projects',
        icon: PROJECTS_ICON_ACTIVE,
        path: PROJECTS_PATH,
        title: PROJECTS_TITLE,
        description: PHI_PROJECTS_DESCRIPTION_TEXT,
        hasNewFeatures: true,
    },
    {
        id: 'landing-release-notes',
        icon: RELEASE_NOTES_ICON,
        path: RELEASE_NOTES_PATH,
        title: RELEASE_NOTES_TITLE,
        description: RELEASE_NOTES_DESCRIPTION,
        shouldOpenInNewTab: true,
    },
];

const PHI_PLANT_FEATURE_CARD_ITEMS: MenuItem[] = [
    {
        id: 'landing-discovery',
        icon: DISCOVERY_ICON_ACTIVE,
        path: MY_DISCOVERY_PATH,
        title: OPEN_DISCOVERY_TITLE,
        description: PHI_PLANT_DISCOVERY_DESCRIPTION_TEXT,
    },
    {
        id: 'landing-projects',
        icon: PROJECTS_ICON_ACTIVE,
        path: PROJECTS_PATH,
        title: PROJECTS_TITLE,
        description: PHI_PLANT_PROJECTS_DESCRIPTION_TEXT,
        hasNewFeatures: false,
    },
    {
        id: 'landing-release-notes',
        icon: RELEASE_NOTES_ICON,
        path: RELEASE_NOTES_PATH,
        title: RELEASE_NOTES_TITLE,
        description: RELEASE_NOTES_DESCRIPTION,
        shouldOpenInNewTab: true,
    },
];

export const FEATURE_CARD_ITEMS: MenuItem[] =
    environment.app === 'd3'
        ? D3_FEATURE_CARD_ITEMS
        : environment.app === 'dia'
        ? INGREDIENT_ANALYZER_FEATURE_CARD_ITEMS
        : environment.app === 'phi'
        ? PHI_FEATURE_CARD_ITEMS
        : environment.app === 'phiplant'
        ? PHI_PLANT_FEATURE_CARD_ITEMS
        : [];

export const NEW_FEATURES_TEXT: string = 'New Features';
