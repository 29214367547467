import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

/** Constants */
import { EMPTY_STRING } from '@leap-common/constants/common';

/** Interfaces */
import DecoratedInputOptions from './decorated-input-options.interface';
import DecoratedInputIcon from './decorated-input-icon.interface';

@Component({
    selector: 'lib-decorated-input',
    templateUrl: 'decorated-input.component.html',
    styleUrls: ['decorated-input.component.scss'],
})
export class DecoratedInputComponent implements OnInit, OnDestroy {
    // `hasInputValue` is getting updated every time a change is made in input's value,
    // while `isEmpty` is getting updated every time a form field change update happens.
    @Input() parentForm: FormGroup;
    @Input() options: DecoratedInputOptions;
    @Input() hideEndIcons: boolean;
    @Input() isDisabled: boolean;
    @Input() hasInputValue: boolean;
    @Input() isExpandedViewEnabled: boolean;
    @Output() expandToggled: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() cleared: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() visibilityToggled: EventEmitter<string> = new EventEmitter<string>();

    isEmpty: boolean = true;
    subscription: Subscription;

    readonly emptyString: string = EMPTY_STRING;

    constructor() {}

    ngOnInit(): void {
        // check if the form control has an initial value and set `isEmpty` accordingly
        this.isEmpty = Boolean(!this.parentForm.get(this.options.id).value);

        // subscribe to value changes in the form control to update `isEmpty` dynamically
        this.subscription = this.parentForm
            .get(this.options.id)
            .valueChanges.subscribe((inputValue: string) => {
                // update `isEmpty` whenever the form control's value changes
                this.isEmpty = Boolean(!inputValue);
            });
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    setInputPristine(): void {
        this.parentForm.get(this.options.id).markAsPristine();
    }

    expandInput(): void {
        this.expandToggled.emit(true);
    }

    clearInput(): void {
        if (this.isDisabled || !this.options?.isClearable) {
            return;
        }

        this.parentForm.patchValue({ [this.options.id]: EMPTY_STRING });
        this.setInputPristine();
        this.cleared.emit(true);
    }

    setInputFocus(): void {
        const element: HTMLElement = document.getElementById(this.options.id);

        if (element) {
            element.focus();
        }
    }

    onBeginIconClick(): void {
        if (this.isDisabled) {
            return;
        }

        this.setInputFocus();
    }

    onEndIconClick(icon: DecoratedInputIcon): void {
        if (!icon?.shouldToggleVisibility) {
            return;
        }

        this.visibilityToggled.emit(this.options.id);
    }
}
