/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';

/** custom imports */
import { MetapathsService } from './services/metapaths.service';
import * as metapathsActions from './metapaths.actions';
import PaginatedMetapaths from './interfaces/paginated-metapaths.interface';

@Injectable()
export class MetapathsEffects {
    constructor(private actions$: Actions, private metapathsService: MetapathsService) {}

    getMetapaths$ = createEffect(() =>
        this.actions$.pipe(
            ofType(metapathsActions.getMetapathsRequest),
            mergeMap(
                ({
                    parentInsightId,
                    sourceId,
                    targetId,
                    sourceCategories,
                    targetCategories,
                    sourceSubcategories,
                    targetSubcategories,
                    pageSize,
                    pageIndex,
                }: {
                    parentInsightId: string;
                    sourceId: string;
                    targetId: string;
                    sourceCategories: string[];
                    targetCategories: string[];
                    sourceSubcategories: string[];
                    targetSubcategories: string[];
                    pageSize: number;
                    pageIndex: number;
                }) =>
                    this.metapathsService
                        .getMetapaths(
                            sourceId,
                            targetId,
                            sourceCategories,
                            targetCategories,
                            sourceSubcategories,
                            targetSubcategories,
                            pageSize,
                            pageIndex,
                        )
                        .pipe(
                            map((paginatedMetapaths: PaginatedMetapaths) =>
                                metapathsActions.getMetapathsSuccess({
                                    parentInsightId,
                                    paginatedMetapaths,
                                }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(metapathsActions.getMetapathsFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );
}
