/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

/** custom imports */
import { ClosedDiscoveryService } from './services/closed-discovery.service';
import * as DiscoveryActions from './closed-discovery.actions';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';

@Injectable()
export class ClosedDiscoveryEffects {
    constructor(
        private actions$: Actions,
        private closedDiscoveryService: ClosedDiscoveryService,
    ) {}

    downloadDiscovery$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DiscoveryActions.downloadDiscoveryRequest),
            switchMap(
                ({
                    source,
                    target,
                    preferences,
                    filters,
                }: {
                    source: string;
                    target: string;
                    preferences: UserPreferences;
                    filters?: ExecutionFilters;
                }) =>
                    this.closedDiscoveryService.download(source, target, preferences, filters).pipe(
                        map((blob: Blob) => DiscoveryActions.downloadDiscoverySuccess({ blob })),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(DiscoveryActions.downloadDiscoveryFailure({ errorResponse })),
                        ),
                    ),
            ),
        ),
    );

    getDiscoverySuggestions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DiscoveryActions.getSearchSuggestionsRequest),
            switchMap(
                ({ source, target, query }: { source: string; target: string; query: string }) =>
                    this.closedDiscoveryService.getSearchSuggestions(source, target, query).pipe(
                        map((suggestionIds: string[]) =>
                            DiscoveryActions.getSearchSuggestionsSuccess({
                                suggestionIds,
                            }),
                        ),
                        catchError((errorResponse: HttpErrorResponse) =>
                            of(
                                DiscoveryActions.getSearchSuggestionsFailure({
                                    errorResponse,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );
}
