/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/** custom imports */
import { environment } from '@environments/leap/environment';
import { BookmarksParser } from '../parsers/bookmarks.parser';
import { SynonymsParser } from '../../discovery/synonyms/parsers/synonyms.parser';
import { DefinitionsParser } from '../../discovery/definitions/parsers/definitions.parser';
import { TermsParser } from '../../discovery/terms/parsers/terms.parser';
import { DatabasesParser } from '../../discovery/databases/parsers/databases.parser';
import { ConcentrationsParser } from '../../discovery/concentrations/parsers/concentrations.parser';
import { MetadataParser } from '../../metadata/parsers/metadata.parser';
import { ArticlesParser } from '../../articles/parsers/articles.parser';

/** Constants */
import { EMPTY_STRING, NO_OP_CALLBACK } from '@leap-common/constants/common';
import { SERIALIZED_SORTING_FIELD } from '../constants/bookmarks';

/** Interfaces - Types - Enums */
import FileExtension from '@leap-common/enums/file-extension.enum';
import Alert from '@leap-store/core/src/lib/ui/alerts/interfaces/alert.interface';
import PaginatedBookmarksRestApi from '../rest-api-interfaces/paginated-bookmarks.rest.interface';
import PaginatedBookmarks from '../interfaces/paginated-bookmarks.interface';
import Project from '../../projects/interfaces/project.interface';
import BookmarkType from '../enums/bookmark.enum';
import BookmarkOrigin from '../interfaces/origin.interface';
import BookmarkOriginType from '../enums/origin.enum';
import BookmarkConfiguration from '../interfaces/configuration.interface';
import BookmarkConfigurationRestApi from '../rest-api-types/configuration.rest.type';
import BookmarkIds from '@leap-store/core/src/lib/data/bookmarks/interfaces/bookmark-ids.interface';
import Synonyms from '../../discovery/synonyms/types/synonyms.type';
import SynonymsRestApi from '../../discovery/synonyms/rest-api-types/synonyms.rest.type';
import Definitions from '../../discovery/definitions/types/definitions.type';
import DefinitionsRestApi from '../../discovery/definitions/rest-api-types/definitions.rest.type';
import TermOverview from '../../discovery/terms/interfaces/term-overview.interface';
import TermOverviewRestApi from '../../discovery/terms/rest-api-interfaces/term-overview.rest.interface';
import Database from '../../discovery/databases/interfaces/database.interface';
import DatabaseRestApi from '../../discovery/databases/rest-api-interfaces/database.rest.interface';
import Concentration from '../../discovery/concentrations/interfaces/concentration.interface';
import ConcentrationRestApi from '../../discovery/concentrations/rest-api-interfaces/concentration.rest.interface';
import Identifiers from '../../metadata/types/identifiers.type';
import IdentifiersRestApi from '../../metadata/rest-api-types/identifiers.rest.type';
import ContainingIngredients from '../../metadata/types/containing-ingredients.type';
import ContainingIngredientsRestApi from '../../metadata/rest-api-types/containing-ingredients.rest.type';
import OriginsInfoPerRelationship from '../../metadata/types/origins-info-per-relationship.type';
import OriginsInfoPerRelationshipRestApi from '../../metadata/rest-api-types/origins-info-per-relationship.rest.type';
import ArticleInfo from '../../articles/interfaces/article-info.interface';
import ArticleInfoRestApi from '../../articles/rest-api-interfaces/article-info.rest.interface';
import ResultsRestApi from '@leap-common/rest-api-interfaces/results.rest.interface';
import PaginatedArticles from '../../articles/interfaces/paginated-articles.interface';
import PaginatedArticlesRestApi from '../../articles/rest-api-interfaces/paginated-articles.rest.interface';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import CreatedArticleBookmarksRestApi from '../rest-api-types/created-article-bookmarks.rest.type';

@Injectable()
export class BookmarksService {
    constructor(
        private http: HttpClient,
        private bookmarksParser: BookmarksParser,
        private synonymsParser: SynonymsParser,
        private definitionsParser: DefinitionsParser,
        private databasesParser: DatabasesParser,
        private termsParser: TermsParser,
        private concentrationsParser: ConcentrationsParser,
        private metadataParser: MetadataParser,
        private articlesParser: ArticlesParser,
    ) {}

    createEntityBookmarks(
        projects: Project[],
        origin: BookmarkOrigin,
        configuration: BookmarkConfiguration,
        notes: string,
    ): Observable<{ alert: Alert }> {
        const serializedOrigin: BookmarkConfigurationRestApi =
            this.bookmarksParser.serializeOrigin(origin);
        const serializedConfiguration: BookmarkConfigurationRestApi =
            this.bookmarksParser.serializeConfiguration(configuration);

        const createBookmarkRequests: Observable<unknown>[] = projects.map((project: Project) =>
            this.http.post(`${environment.projectsServerUrl}/projects/${project.id}/entities/`, {
                origin: serializedOrigin,
                configuration: serializedConfiguration,
                note: notes,
            }),
        );

        return forkJoin(createBookmarkRequests).pipe(
            map(() => ({ alert: this.bookmarksParser.getCreatedBookmarksAlert(projects) })),
        );
    }

    createAssociationOpenBookmarks(
        projects: Project[],
        configuration: BookmarkConfiguration,
        notes: string,
    ): Observable<{ alert: Alert }> {
        const serializedOrigin: BookmarkConfigurationRestApi = this.bookmarksParser.serializeOrigin(
            {
                type: BookmarkOriginType.openDiscovery,
                sourceId: configuration.sourceId,
                sourceName: configuration.sourceName,
            },
        );
        const serializedConfiguration: BookmarkConfigurationRestApi =
            this.bookmarksParser.serializeConfiguration(configuration);

        const createBookmarkRequests: Observable<unknown>[] = projects.map((project: Project) =>
            this.http.post(
                `${environment.projectsServerUrl}/projects/${project.id}/associations/`,
                {
                    origin: serializedOrigin,
                    configuration: serializedConfiguration,
                    note: notes,
                },
            ),
        );

        return forkJoin(createBookmarkRequests).pipe(
            map(() => ({ alert: this.bookmarksParser.getCreatedBookmarksAlert(projects) })),
        );
    }

    createAssociationClosedBookmarks(
        projects: Project[],
        configuration: BookmarkConfiguration,
        notes: string,
    ): Observable<{ alert: Alert }> {
        const serializedOrigin: BookmarkConfigurationRestApi = this.bookmarksParser.serializeOrigin(
            {
                type: BookmarkOriginType.closedDiscovery,
                sourceId: configuration.sourceId,
                sourceName: configuration.sourceName,
                targetId: configuration.targetId,
                targetName: configuration.targetName,
            },
        );
        const serializedConfiguration: BookmarkConfigurationRestApi =
            this.bookmarksParser.serializeConfiguration(configuration);

        const createBookmarkRequests: Observable<unknown>[] = projects.map((project: Project) =>
            this.http.post(
                `${environment.projectsServerUrl}/projects/${project.id}/associations/`,
                {
                    origin: serializedOrigin,
                    configuration: serializedConfiguration,
                    note: notes,
                },
            ),
        );

        return forkJoin(createBookmarkRequests).pipe(
            map(() => ({ alert: this.bookmarksParser.getCreatedBookmarksAlert(projects) })),
        );
    }

    createArticleBookmarks(
        projects: Project[],
        origin: BookmarkOrigin,
        configurations: BookmarkConfiguration[],
        notes: string,
    ): Observable<{ alert: Alert }> {
        const serializedOrigin: BookmarkConfigurationRestApi =
            this.bookmarksParser.serializeOrigin(origin);
        const serializedConfiguration: BookmarkConfigurationRestApi =
            this.bookmarksParser.serializeConfigurations(configurations, origin);

        const createBookmarkRequests: Observable<CreatedArticleBookmarksRestApi>[] = projects.map(
            (project: Project) =>
                this.http.post<CreatedArticleBookmarksRestApi>(
                    `${environment.projectsServerUrl}/projects/${project.id}/articles/batch/`,
                    {
                        origin: serializedOrigin,
                        configuration: serializedConfiguration,
                        note: notes,
                    },
                ),
        );

        return forkJoin(createBookmarkRequests).pipe(
            map((createdArticles: CreatedArticleBookmarksRestApi[]) => ({
                alert: this.bookmarksParser.getCreatedArticleBookmarksAlert(
                    createdArticles,
                    projects,
                    configurations.length,
                ),
            })),
        );
    }

    uploadArticle(projectId: string, doi: string): Observable<void> {
        return this.http
            .post(
                `${environment.projectsServerUrl}/projects/${projectId}/articles/user-uploaded/`,
                {
                    doi,
                },
            )
            .pipe(map(NO_OP_CALLBACK));
    }

    getBookmarks(
        projectId: string,
        type: BookmarkType,
        filters: ExecutionFilters,
        preferences: UserPreferences,
        studyTypesOrder: string[],
        pageIndex: number,
        pageSize: number,
        sortingOptions: SortingOptions,
    ): Observable<PaginatedBookmarks> {
        const bookmarksPath: string =
            type === BookmarkType.entity
                ? 'entities/details/'
                : type === BookmarkType.associationOpen
                ? 'associations/open-discovery/'
                : type === BookmarkType.associationClosed
                ? 'associations/closed-discovery/'
                : type === BookmarkType.article
                ? 'articles/details/'
                : undefined;
        const sortingBy: string = SERIALIZED_SORTING_FIELD[type]?.[sortingOptions.field];
        const serializedFilters: ExecutionFilters | { filters: ExecutionFilters } =
            type === BookmarkType.article ? filters : { filters };

        return this.http
            .post(`${environment.projectsServerUrl}/projects/${projectId}/${bookmarksPath}`, {
                ...serializedFilters,
                preferences,
                pageSize,
                pageIndex,
                sortingBy,
                sortingOrder: sortingOptions.order,
            })
            .pipe(
                map((paginatedResults: PaginatedBookmarksRestApi) =>
                    this.bookmarksParser.parsePaginatedResults(
                        paginatedResults,
                        type,
                        studyTypesOrder,
                    ),
                ),
            );
    }

    downloadArticleBookmarks(projectId: string, filters: ExecutionFilters): Observable<Blob> {
        return this.http.post(
            `${environment.projectsServerUrl}/projects/${projectId}/articles/download/`,
            { ...filters },
            {
                headers: new HttpHeaders({
                    Accept: `text/${FileExtension.xlsx}`,
                }),
                responseType: 'blob',
            },
        );
    }

    updateNotes({ type, id, projectId }: BookmarkIds, notes: string): Observable<void> {
        const serializedType: string = this.bookmarksParser.serializeBookmarkType(type, true);

        return this.http
            .patch(
                `${environment.projectsServerUrl}/projects/${projectId}/bookmarks/${serializedType}/${id}/note/`,
                {
                    note: notes || EMPTY_STRING,
                },
            )
            .pipe(map(NO_OP_CALLBACK));
    }

    deleteBookmark(projectId: string, bookmarkId: string, type: BookmarkType): Observable<void> {
        const serializedType: string = this.bookmarksParser.serializeBookmarkType(type, true);

        return this.http
            .delete(
                `${environment.projectsServerUrl}/projects/${projectId}/${serializedType}/${bookmarkId}/`,
            )
            .pipe(map(NO_OP_CALLBACK));
    }

    getSynonyms({ type, id, projectId }: BookmarkIds): Observable<Synonyms> {
        const serializedType: string = this.bookmarksParser.serializeBookmarkType(type, true);

        return this.http
            .get(
                `${environment.projectsServerUrl}/projects/${projectId}/${serializedType}/${id}/synonyms/`,
            )
            .pipe(map((synonyms: SynonymsRestApi) => this.synonymsParser.parseSynonyms(synonyms)));
    }

    getDefinitions({ type, id, projectId }: BookmarkIds): Observable<Definitions> {
        const serializedType: string = this.bookmarksParser.serializeBookmarkType(type, true);

        return this.http
            .get(
                `${environment.projectsServerUrl}/projects/${projectId}/${serializedType}/${id}/definitions/`,
            )
            .pipe(
                map((definitions: DefinitionsRestApi) =>
                    this.definitionsParser.parseDefinitions(definitions),
                ),
            );
    }

    getTermsOverview({ type, id, projectId }: BookmarkIds): Observable<TermOverview[]> {
        const serializedType: string = this.bookmarksParser.serializeBookmarkType(type, true);

        return this.http
            .get(
                `${environment.projectsServerUrl}/projects/${projectId}/${serializedType}/${id}/concepts/details/`,
            )
            .pipe(
                map((terms: ResultsRestApi<TermOverviewRestApi>) =>
                    this.termsParser.parseTermsOverview(terms.results),
                ),
            );
    }

    getDatabases({ type, id, projectId }: BookmarkIds): Observable<Record<string, Database>> {
        const serializedType: string = this.bookmarksParser.serializeBookmarkType(type, true);

        return this.http
            .get(
                `${environment.projectsServerUrl}/projects/${projectId}/${serializedType}/${id}/database-origins/`,
            )
            .pipe(
                map((databases: Record<string, DatabaseRestApi>) =>
                    this.databasesParser.parseDatabases(databases),
                ),
            );
    }

    getConcentrations(
        { type, id, projectId }: BookmarkIds,
        sourceId: string,
        targetId: string,
    ): Observable<Concentration[]> {
        const serializedType: string = this.bookmarksParser.serializeBookmarkType(type, true);

        return this.http
            .post(
                `${environment.projectsServerUrl}/projects/${projectId}/${serializedType}/${id}/concentrations/`,
                {
                    sourceUid: sourceId,
                    targetUid: targetId,
                },
            )
            .pipe(
                map((concentration: ResultsRestApi<ConcentrationRestApi>) =>
                    this.concentrationsParser.parseConcentrations(concentration.results),
                ),
            );
    }

    getIdentifiers({ type, id, projectId }: BookmarkIds): Observable<Identifiers> {
        const serializedType: string = this.bookmarksParser.serializeBookmarkType(type, true);

        return this.http
            .get(
                `${environment.projectsServerUrl}/projects/${projectId}/${serializedType}/${id}/identifiers/`,
            )
            .pipe(
                map((identifiers: IdentifiersRestApi) =>
                    this.metadataParser.parseIdentifiers(identifiers),
                ),
            );
    }

    getContainingIngredients({
        type,
        id,
        projectId,
    }: BookmarkIds): Observable<ContainingIngredients> {
        const serializedType: string = this.bookmarksParser.serializeBookmarkType(type, true);

        return this.http
            .get(
                `${environment.projectsServerUrl}/projects/${projectId}/${serializedType}/${id}/ingredients/`,
            )
            .pipe(
                map((ingredients: ContainingIngredientsRestApi) =>
                    this.metadataParser.parseContainingIngredients(ingredients),
                ),
            );
    }

    getSourceInfoPerRelationship(
        { type, id, projectId }: BookmarkIds,
        ids: [string, string][],
    ): Observable<Record<string, OriginsInfoPerRelationship>> {
        const serializedType: string = this.bookmarksParser.serializeBookmarkType(type, true);

        return this.http
            .post(
                `${environment.projectsServerUrl}/projects/${projectId}/${serializedType}/${id}/relation-sources/`,
                {
                    cuiTuples: ids,
                },
            )
            .pipe(
                map((relationshipSourcesInfoMap: OriginsInfoPerRelationshipRestApi) =>
                    this.metadataParser.parseRelationshipsOriginsInfo(relationshipSourcesInfoMap),
                ),
            );
    }

    getArticlesInfo(
        { type, id, projectId }: BookmarkIds,
        ids: [string, string, string?][],
    ): Observable<ArticleInfo[]> {
        const serializedType: string = this.bookmarksParser.serializeBookmarkType(type, true);

        return this.http
            .post(
                `${environment.projectsServerUrl}/projects/${projectId}/${serializedType}/${id}/relevant-articles/info/`,
                {
                    cuiTuple: ids[0],
                },
            )
            .pipe(
                map((articlesInfo: ResultsRestApi<ArticleInfoRestApi>) =>
                    this.articlesParser.parseArticlesInfo(articlesInfo.results),
                ),
            );
    }

    getArticles(
        { type, id, projectId }: BookmarkIds,
        ids: [string, string, string?],
        filters: ExecutionFilters,
        pageSize: number,
        pageIndex: number,
        sortingOptions: SortingOptions,
        areResultsHighlighted: boolean,
        searchQuery?: string,
    ): Observable<PaginatedArticles> {
        const serializedType: string = this.bookmarksParser.serializeBookmarkType(type, true);

        return this.http
            .post(
                `${
                    environment.projectsServerUrl
                }/projects/${projectId}/${serializedType}/${id}/relevant-articles/details/${
                    areResultsHighlighted ? '' : '?ignoreSpans'
                }`,
                {
                    cuiTuple: ids,
                    ...filters,
                    sortingBy: sortingOptions.field,
                    sortingOrder: sortingOptions.order,
                    pageSize,
                    pageIndex,
                    ...(searchQuery && { phrase: searchQuery }),
                },
            )
            .pipe(
                map((paginatedResults: PaginatedArticlesRestApi) =>
                    this.articlesParser.parsePaginatedArticles(paginatedResults),
                ),
            );
    }

    downloadArticles(
        { type, id, projectId }: BookmarkIds,
        ids: [string, string, string?],
        filters: ExecutionFilters,
        searchQuery: string,
        sortingOptions?: SortingOptions,
        pageSize?: number,
        pageIndex?: number,
    ): Observable<Blob> {
        const serializedType: string = this.bookmarksParser.serializeBookmarkType(type, true);

        return this.http.post(
            `${environment.projectsServerUrl}/projects/${projectId}/${serializedType}/${id}/relevant-articles/details/download/`,
            {
                cuiTuple: ids,
                ...filters,
                sortingBy: sortingOptions?.field,
                sortingOrder: sortingOptions?.order,
                ...(pageSize && { pageSize }),
                ...(pageIndex && { pageIndex }),
                ...(searchQuery && { phrase: searchQuery }),
            },
            {
                headers: new HttpHeaders({
                    Accept: `text/${FileExtension.xlsx}`,
                }),
                responseType: 'blob',
            },
        );
    }
}
