/** third-party imports */
import { Injectable } from '@angular/core';
import { ReportParser } from '../../report/parsers/report.parser';
import AssociationsRestApi from '../rest-api-interfaces/associations.rest.interface';
import Associations from '../interfaces/associations.interface';
import KeyFindingRestApi from '../rest-api-interfaces/key-finding.rest.interface';
import KeyFinding from '../interfaces/key-finding.interface';
import TheoreticalFrameworkItemRestApi from '../rest-api-interfaces/theoretical-framework-item.rest.interface';
import TheoreticalFrameworkItem from '../interfaces/theoretical-framework-item.interface';
import Article from '../../articles/interfaces/article.interface';
import Summary from '../../report/interfaces/summary.interface';
import TermSummaryRestApi from '../rest-api-interfaces/term-summary.rest.interface';
import AssociationArticlesRestApi from '../rest-api-interfaces/association-articles.rest.interface';
import AssociationArticles from '../interfaces/association-articles.interface';

@Injectable()
export class ArticlesReportParser {
    constructor(private reportParser: ReportParser) {}

    parseAssociations(associations: AssociationsRestApi): Associations {
        return {
            publicationDates: this.reportParser.parsePublicationDates(
                associations?.publicationDate,
            ),
            studyTypeStatistics: this.reportParser.parseStatisticsItems(
                associations?.counts.typeOfStudy,
            ),
            associationsArticles: this.parseAssociationsArticles(associations?.associatedArticles),
            hasAssociations: associations?.associations_exist,
        };
    }

    parseAssociationsArticles(
        associationsArticles: AssociationArticlesRestApi[],
    ): AssociationArticles[] {
        return (
            associationsArticles?.map((associationsArticle: AssociationArticlesRestApi) => ({
                sourceId: associationsArticle?.sourceUid,
                sourceName: associationsArticle?.sourceName,
                intermediateName: associationsArticle?.intermediateName,
                targetId: associationsArticle?.targetUid,
                targetName: associationsArticle?.targetName,
                references: this.reportParser.parseReferences(associationsArticle?.articles),
            })) || []
        );
    }

    parseKeyFindings(keyFindings: KeyFindingRestApi[], articles: Article[]): KeyFinding[] {
        return (
            keyFindings?.map((keyFinding: KeyFindingRestApi) => {
                const article: Article = articles.find(
                    ({ abstract }: Article) => abstract === keyFinding.abstract,
                );

                return this.parseKeyFinding(keyFinding, article);
            }) || []
        );
    }

    parseKeyFinding(keyFinding: KeyFindingRestApi, article: Article): KeyFinding {
        return {
            article,
            text: keyFinding.keyFinding,
            methodology: keyFinding.methodology,
            targetGroup: keyFinding.targetGroup,
            targetGroupType: keyFinding.targetGroupType,
        };
    }

    parseTermSummaries(termSummaries: TermSummaryRestApi[]): Summary[] {
        return (
            termSummaries?.map((termSummary: TermSummaryRestApi) =>
                this.parseTermSummary(termSummary),
            ) || []
        );
    }

    parseTermSummary(termSummary: TermSummaryRestApi): Summary {
        return {
            name: termSummary.term,
            text: termSummary.summary,
            referenceIds: termSummary.referenceIds,
        };
    }

    parseTheoreticalFrameworkItems(
        items: TheoreticalFrameworkItemRestApi[],
        articles: Article[],
    ): TheoreticalFrameworkItem[] {
        return (
            items?.map((item: TheoreticalFrameworkItemRestApi) => {
                const article: Article = articles.find(
                    ({ abstract }: Article) => abstract === item.abstract,
                );

                return this.parseTheoreticalFrameworkItem(item, article);
            }) || []
        );
    }

    parseTheoreticalFrameworkItem(
        item: TheoreticalFrameworkItemRestApi,
        article: Article,
    ): TheoreticalFrameworkItem {
        return {
            article,
            text: item.theoreticalFramework,
            categories: item.frameworkCategories,
        };
    }
}
