/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, switchMap, map, catchError } from 'rxjs/operators';

/** custom imports */
import { NotebooksService } from './services/notebooks.service';
import * as NotebooksActions from './notebooks.actions';
import SortingOrder from '@leap-common/enums/sorting-order.enum';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import Notebook from './interfaces/notebook.interface';

@Injectable()
export class NotebooksEffects {
    constructor(private actions$: Actions, private notebooksService: NotebooksService) {}

    getNotebooks$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotebooksActions.getNotebooksRequest),
            mergeMap(
                ({
                    pageIndex,
                    pageSize,
                    sortDirection,
                    sortColumn,
                    ownerType,
                    suppress,
                }: {
                    pageIndex: number;
                    pageSize: number;
                    sortDirection: SortingOrder;
                    sortColumn: string;
                    ownerType: string;
                    suppress: boolean;
                }) =>
                    this.notebooksService
                        .getNotebooks(pageIndex, pageSize, sortDirection, sortColumn, ownerType)
                        .pipe(
                            map(
                                ({
                                    paginatedNotebooks,
                                    type,
                                }: {
                                    paginatedNotebooks: PaginatedResults<Notebook>;
                                    type: string;
                                }) =>
                                    NotebooksActions.getNotebooksSuccess({
                                        paginatedNotebooks,
                                        sortDirection,
                                        sortColumn,
                                        ownerType: type,
                                    }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(
                                    NotebooksActions.getNotebooksFailure({
                                        errorResponse,
                                        suppress,
                                    }),
                                ),
                            ),
                        ),
            ),
        ),
    );

    getNotebook$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotebooksActions.getNotebookRequest),
            switchMap(({ id }: { id: string }) =>
                this.notebooksService.getNotebook(id).pipe(
                    map((notebook: Notebook) => NotebooksActions.getNotebookSuccess({ notebook })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(NotebooksActions.getNotebookFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    createNotebook$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotebooksActions.createNotebookRequest),
            switchMap(({ name }: { name?: string }) =>
                this.notebooksService.createNotebook(name).pipe(
                    map((notebook: Notebook) =>
                        NotebooksActions.createNotebookSuccess({ notebook }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(NotebooksActions.createNotebookFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    cloneNotebook$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotebooksActions.cloneNotebookRequest),
            switchMap(({ id }: { id: string }) =>
                this.notebooksService.cloneNotebook(id).pipe(
                    map((notebook: Notebook) =>
                        NotebooksActions.cloneNotebookSuccess({ notebook }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(NotebooksActions.cloneNotebookFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    updateNotebook$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotebooksActions.updateNotebookRequest),
            switchMap(({ id, name }: { id: string; name: string }) =>
                this.notebooksService.updateNotebook(id, name).pipe(
                    map((notebook: Notebook) =>
                        NotebooksActions.updateNotebookSuccess({ notebook }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(NotebooksActions.updateNotebookFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    deleteNotebook$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotebooksActions.deleteNotebookRequest),
            mergeMap(({ id }: { id: string }) =>
                this.notebooksService.deleteNotebook(id).pipe(
                    map(() => NotebooksActions.deleteNotebookSuccess({ id })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(NotebooksActions.deleteNotebookFailure({ id, errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
