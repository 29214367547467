/** third-party imports */
import { Injectable } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, Subscription, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

/**  custom imports */
import { IngredientProfilerService } from './services/ingredient-profiler.service';
import { IngredientProfilerState } from './ingredient-profiler-state.interface';
import InsightFilterCounts from '@apps/leap/src/app/shared/modules/insight-filters/interfaces/counts.interface';
import {
    getSuggestionsRequest,
    getCustomIngredientRequest,
    quitCustomIngredientRequest,
    getCompoundsRequest,
    getCompoundsSuccess,
    getCompoundsFailure,
    getInsightsRequest,
    getIngredientInsightsRequest,
    getIngredientInsightsSuccess,
    getIngredientInsightsFailure,
    getTargetInsightsRequest,
    downloadInsightsRequest,
    getCategoriesRequest,
    getOverviewRequest,
    getHealthLabelsStatisticsRequest,
    getHealthLabelSummariesRequest,
    getHealthLabelTopCompoundsRequest,
    getSearchSuggestionsRequest,
    clearSearchSuggestions,
    resetDiscovery,
    clearNextError,
} from './ingredient-profiler.actions';
import {
    getSuggestions,
    getSuggestionsLoading,
    getSuggestionsLoaded,
    getCustomIngredient,
    getCustomIngredientLoading,
    getCustomIngredientLoaded,
    getCompoundsTotal,
    getCompoundsDisplaying,
    getCompoundsLoading,
    getCompoundsLoaded,
    getMinSourceConcentration,
    getMaxSourceConcentration,
    getShouldShowSourceConcentration,
    getParentCompoundId,
    getInsights,
    getEnhancedInsights,
    getInsightsTotal,
    getInsightsDisplaying,
    getInsightsPageIndex,
    getInsightsLoading,
    getInsightsLoaded,
    getIngredientInsightsTotal,
    getIngredientInsightsLoading,
    getIngredientInsightsLoaded,
    getTargetInsights,
    getTargetInsightsTotal,
    getTargetInsightsLoading,
    getTargetInsightsLoaded,
    getOldestOccurrence,
    getNewestOccurrence,
    getIsCustomIngredient,
    getBlob,
    getCategories,
    getSubcategoriesPerCategory,
    getCategoriesLoading,
    getCategoriesLoaded,
    getOverview,
    getOverviewLoading,
    getOverviewLoaded,
    getHealthLabelsStatistics,
    getHealthLabelsStatisticsLoading,
    getHealthLabelsStatisticsLoaded,
    getRelationshipsPerGroup,
    getHealthLabelSummaries,
    getHealthLabelTopCompounds,
    getSearchSuggestions,
    getSearchSuggestionsLoading,
    getSearchSuggestionsLoaded,
    getErrors,
} from './ingredient-profiler.selectors';
import { createDistinctUntilObjectChanged } from '@leap-common/utilities/helpers';
import ErrorResponse from '@leap-common/interfaces/error-response.interface';
import Suggestion from '@apps/leap/src/app/shared/interfaces/suggestion.interface';
import PaginatedCompounds from './interfaces/paginated-compounds.interface';
import PaginatedInsights from './interfaces/paginated-insights.interface';
import Compound from './interfaces/compound.interface';
import Insight from './interfaces/insight.interface';
import EnhancedInsights from './interfaces/enhanced-insights';
import ExecutionFilters from '@apps/leap/src/app/shared/modules/filters/types/execution-filters.type';
import RelationshipGroup from './enums/relationship-group.enum';
import Overview from './interfaces/overview.interface';
import HealthLabelStatistics from './interfaces/health-label-statistics.interface';
import HealthLabelSummaries from './interfaces/health-label-summaries.interface';
import HealthLabelTopCompounds from './interfaces/health-label-top-compounds.interface';
import SortingOptions from '@leap-common/interfaces/sorting-options.interface';
import CustomIngredient from './interfaces/custom-ingredient.interface';
import UserPreferences from '@apps/leap/src/app/shared/types/user-preferences.type';
import ProfilerSearch from '@apps/leap/src/app/shared/enums/profiler-search.enum';

@Injectable()
export class IngredientProfilerFacade {
    errors$: Observable<ErrorResponse[]> = this.store.pipe(select(getErrors));
    suggestions$: Observable<Suggestion[]> = this.store.pipe(select(getSuggestions));
    suggestionsLoading$: Observable<boolean> = this.store.pipe(select(getSuggestionsLoading));
    suggestionsLoaded$: Observable<boolean> = this.store.pipe(select(getSuggestionsLoaded));
    customIngredient$: Observable<CustomIngredient> = this.store.pipe(select(getCustomIngredient));
    customIngredientLoading$: Observable<boolean> = this.store.pipe(
        select(getCustomIngredientLoading),
    );
    customIngredientLoaded$: Observable<boolean> = this.store.pipe(
        select(getCustomIngredientLoaded),
    );
    compounds$: BehaviorSubject<Compound[] | null> = new BehaviorSubject(null);
    compoundsTotal$: Observable<number> = this.store.pipe(select(getCompoundsTotal));
    compoundsDisplaying$: Observable<number> = this.store.pipe(select(getCompoundsDisplaying));
    compoundsLoading$: Observable<boolean> = this.store.pipe(select(getCompoundsLoading));
    compoundsLoaded$: Observable<boolean> = this.store.pipe(select(getCompoundsLoaded));
    minSourceConcentration$: Observable<number> = this.store.pipe(
        select(getMinSourceConcentration),
    );
    maxSourceConcentration$: Observable<number> = this.store.pipe(
        select(getMaxSourceConcentration),
    );
    parentCompoundId$: Observable<string> = this.store.pipe(select(getParentCompoundId));
    insights$: Observable<Insight[]> = this.store.pipe(select(getInsights));
    enhancedInsights$: Observable<EnhancedInsights> = this.store.pipe(
        select(getEnhancedInsights),
        createDistinctUntilObjectChanged<EnhancedInsights>(),
    );
    insightsTotal$: Observable<number> = this.store.pipe(select(getInsightsTotal));
    insightsDisplaying$: Observable<number> = this.store.pipe(select(getInsightsDisplaying));
    insightsPageIndex$: Observable<number> = this.store.pipe(select(getInsightsPageIndex));
    insightsLoading$: Observable<boolean> = this.store.pipe(select(getInsightsLoading));
    insightsLoaded$: Observable<boolean> = this.store.pipe(select(getInsightsLoaded));
    ingredientInsightsTotal$: Observable<number> = this.store.pipe(
        select(getIngredientInsightsTotal),
    );
    ingredientInsights$: BehaviorSubject<Insight[] | null> = new BehaviorSubject(null);
    ingredientInsightsLoading$: Observable<boolean> = this.store.pipe(
        select(getIngredientInsightsLoading),
    );
    ingredientInsightsLoaded$: Observable<boolean> = this.store.pipe(
        select(getIngredientInsightsLoaded),
    );
    targetInsights$: Observable<Insight[]> = this.store.pipe(select(getTargetInsights));
    targetInsightsTotal$: Observable<number> = this.store.pipe(select(getTargetInsightsTotal));
    targetInsightsLoading$: Observable<boolean> = this.store.pipe(select(getTargetInsightsLoading));
    targetInsightsLoaded$: Observable<boolean> = this.store.pipe(select(getTargetInsightsLoaded));
    filterCounts$: BehaviorSubject<InsightFilterCounts | null> = new BehaviorSubject(null);
    oldestOccurrence$: Observable<number> = this.store.pipe(select(getOldestOccurrence));
    newestOccurrence$: Observable<number> = this.store.pipe(select(getNewestOccurrence));
    shouldShowSourceConcentration$: Observable<boolean> = this.store.pipe(
        select(getShouldShowSourceConcentration),
    );
    isCustomIngredient$: Observable<boolean> = this.store.pipe(select(getIsCustomIngredient));
    blob$: Observable<Blob> = this.store.pipe(select(getBlob));
    categories$: Observable<string[]> = this.store.pipe(select(getCategories));
    subcategoriesPerCategory$: Observable<Record<string, string[]>> = this.store.pipe(
        select(getSubcategoriesPerCategory),
    );
    categoriesLoading$: Observable<boolean> = this.store.pipe(select(getCategoriesLoading));
    categoriesLoaded$: Observable<boolean> = this.store.pipe(select(getCategoriesLoaded));
    overview$: Observable<Overview> = this.store.pipe(select(getOverview));
    overviewLoading$: Observable<boolean> = this.store.pipe(select(getOverviewLoading));
    overviewLoaded$: Observable<boolean> = this.store.pipe(select(getOverviewLoaded));
    healthLabelsStatistics$: Observable<HealthLabelStatistics[]> = this.store.pipe(
        select(getHealthLabelsStatistics),
    );
    healthLabelsStatisticsLoading$: Observable<boolean> = this.store.pipe(
        select(getHealthLabelsStatisticsLoading),
    );
    healthLabelsStatisticsLoaded$: Observable<boolean> = this.store.pipe(
        select(getHealthLabelsStatisticsLoaded),
    );
    relationshipsPerGroup$: Observable<Record<RelationshipGroup, string[]>> = this.store.pipe(
        select(getRelationshipsPerGroup),
    );
    healthLabelSummaries$: Observable<HealthLabelSummaries> = this.store.pipe(
        select(getHealthLabelSummaries),
    );
    healthLabelTopCompounds$: Observable<HealthLabelTopCompounds> = this.store.pipe(
        select(getHealthLabelTopCompounds),
    );
    searchSuggestions$: Observable<string[]> = this.store.pipe(select(getSearchSuggestions));
    searchSuggestionsLoading$: Observable<boolean> = this.store.pipe(
        select(getSearchSuggestionsLoading),
    );
    searchSuggestionsLoaded$: Observable<boolean> = this.store.pipe(
        select(getSearchSuggestionsLoaded),
    );

    // internal variables
    internalCompounds$: Observable<void>;
    compoundsSubscription: Subscription;
    internalIngredientInsights$: Observable<void>;
    ingredientInsightsSubscription: Subscription;

    constructor(
        private store: Store<IngredientProfilerState>,
        private ingredientProfilerService: IngredientProfilerService,
    ) {}

    getSuggestions(query: string, limit: number): void {
        this.store.dispatch(getSuggestionsRequest({ query, limit }));
    }

    getCustomIngredient(file: File): void {
        this.store.dispatch(getCustomIngredientRequest({ file }));
    }

    quitCustomIngredientRequest(): void {
        this.store.dispatch(quitCustomIngredientRequest());
    }

    getCompounds({
        ingredientId,
        filters,
        pageSize,
        pageIndex,
        sortingOptions,
    }: {
        ingredientId: string;
        filters: ExecutionFilters;
        pageSize?: number;
        pageIndex?: number;
        sortingOptions: SortingOptions;
    }): void {
        this.store.dispatch(
            getCompoundsRequest({ ingredientId, filters, pageSize, pageIndex, sortingOptions }),
        );

        this.compoundsSubscription?.unsubscribe();

        this.internalCompounds$ = this.ingredientProfilerService
            .getCompounds(ingredientId, filters, pageSize, pageIndex, sortingOptions)
            .pipe(
                map((paginatedCompounds: PaginatedCompounds) => {
                    this.compounds$.next(paginatedCompounds?.results);
                    this.filterCounts$.next(paginatedCompounds?.filterCounts);
                    this.store.dispatch(
                        getCompoundsSuccess({
                            paginatedCompounds: { ...paginatedCompounds, results: null },
                        }),
                    );
                }),
                catchError((errorResponse: HttpErrorResponse) =>
                    of(this.store.dispatch(getCompoundsFailure({ errorResponse }))),
                ),
            );

        this.compoundsSubscription = this.internalCompounds$.subscribe();
    }

    getInsights({
        ingredientId,
        compoundId,
        filters,
        pageSize,
        pageIndex,
        preferences,
        sortingOptions,
    }: {
        ingredientId: string;
        compoundId: string;
        filters: ExecutionFilters;
        pageSize?: number;
        pageIndex?: number;
        preferences?: UserPreferences;
        sortingOptions?: SortingOptions;
    }): void {
        this.store.dispatch(
            getInsightsRequest({
                ingredientId,
                compoundId,
                filters,
                pageSize,
                pageIndex,
                preferences,
                sortingOptions,
            }),
        );
    }

    getIngredientInsights({
        ingredientId,
        filters,
        pageSize,
        pageIndex,
        preferences,
    }: {
        ingredientId: string;
        filters: ExecutionFilters;
        pageSize?: number;
        pageIndex?: number;
        preferences?: UserPreferences;
    }): void {
        this.store.dispatch(
            getIngredientInsightsRequest({
                ingredientId,
                filters,
                pageSize,
                pageIndex,
                preferences,
            }),
        );

        this.ingredientInsightsSubscription?.unsubscribe();

        this.internalIngredientInsights$ = this.ingredientProfilerService
            .getIngredientInsights(ingredientId, filters, pageSize, pageIndex, preferences)
            .pipe(
                map((paginatedInsights: PaginatedInsights) => {
                    this.ingredientInsights$.next(paginatedInsights?.results);
                    this.store.dispatch(
                        getIngredientInsightsSuccess({
                            paginatedInsights: { ...paginatedInsights, results: null },
                        }),
                    );
                }),
                catchError((errorResponse: HttpErrorResponse) =>
                    of(this.store.dispatch(getIngredientInsightsFailure({ errorResponse }))),
                ),
            );

        this.ingredientInsightsSubscription = this.internalIngredientInsights$.subscribe();
    }

    getTargetInsights({
        ingredientId,
        targetId,
        filters,
        pageSize,
        pageIndex,
        preferences,
    }: {
        ingredientId: string;
        targetId: string;
        filters?: ExecutionFilters;
        pageSize?: number;
        pageIndex?: number;
        preferences?: UserPreferences;
    }): void {
        this.store.dispatch(
            getTargetInsightsRequest({
                ingredientId,
                targetId,
                filters,
                pageSize,
                pageIndex,
                preferences,
            }),
        );
    }

    downloadInsights(
        ingredientId: string,
        filters: ExecutionFilters,
        sortingOptions: SortingOptions,
    ): void {
        this.store.dispatch(downloadInsightsRequest({ ingredientId, filters, sortingOptions }));
    }

    getCategories(): void {
        this.store.dispatch(getCategoriesRequest());
    }

    getOverview({
        ingredientId,
        countBy,
        filters,
        preferences,
    }: {
        ingredientId: string;
        countBy: string;
        filters: ExecutionFilters;
        preferences: UserPreferences;
    }): void {
        this.store.dispatch(getOverviewRequest({ ingredientId, countBy, filters, preferences }));
    }

    getHealthLabelsStatistics({
        ingredientId,
        filters,
        preferences,
        areEffectsEnabled,
    }: {
        ingredientId: string;
        filters: ExecutionFilters;
        preferences: UserPreferences;
        areEffectsEnabled?: boolean;
    }): void {
        this.store.dispatch(
            getHealthLabelsStatisticsRequest({
                ingredientId,
                filters,
                preferences,
                areEffectsEnabled,
            }),
        );
    }

    getHealthLabelSummaries({
        ingredientId,
        healthLabel,
        filters,
        preferences,
        areEffectsEnabled,
    }: {
        ingredientId: string;
        healthLabel: string;
        filters: ExecutionFilters;
        preferences: UserPreferences;
        areEffectsEnabled?: boolean;
    }): void {
        this.store.dispatch(
            getHealthLabelSummariesRequest({
                ingredientId,
                healthLabel,
                filters,
                preferences,
                areEffectsEnabled,
            }),
        );
    }

    getHealthLabelTopCompounds({
        ingredientId,
        healthLabel,
        filters,
        preferences,
        areEffectsEnabled,
    }: {
        ingredientId: string;
        healthLabel: string;
        filters: ExecutionFilters;
        preferences: UserPreferences;
        areEffectsEnabled?: boolean;
    }): void {
        this.store.dispatch(
            getHealthLabelTopCompoundsRequest({
                ingredientId,
                healthLabel,
                filters,
                preferences,
                areEffectsEnabled,
            }),
        );
    }

    getSearchSuggestions(ingredientId: string, query: string, activeSearch: ProfilerSearch): void {
        this.store.dispatch(getSearchSuggestionsRequest({ ingredientId, query, activeSearch }));
    }

    clearSearchSuggestions(): void {
        this.store.dispatch(clearSearchSuggestions());
    }

    resetDiscovery(): void {
        this.compounds$.next(null);
        this.ingredientInsights$.next(null);
        this.filterCounts$.next(null);
        this.store.dispatch(resetDiscovery());
    }

    clearNextError(): void {
        this.store.dispatch(clearNextError());
    }
}
