/** third-party imports */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

/** custom imports */
import { IngredientViewerService } from './services/ingredient-viewer.service';
import { IngredientManagementService } from '../ingredient-management/services/ingredient-management.service';
import { HttpErrorResponse } from '@angular/common/http';
import * as actions from './ingredient-viewer.actions';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import Ingredient from './interfaces/ingredient.interface';
import Statistics from './interfaces/statistics.interface';

@Injectable()
export class IngredientViewerEffects {
    constructor(
        private actions$: Actions,
        private ingredientViewerService: IngredientViewerService,
        private ingredientManagementService: IngredientManagementService,
    ) {}

    getIngredients$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getIngredientsRequest),
            switchMap(({ query }: { query: string }) =>
                this.ingredientViewerService.getIngredients(query).pipe(
                    map((ingredients: Ingredient[]) =>
                        actions.getIngredientsSuccess({ ingredients }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getIngredientsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getIngredient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getIngredientRequest),
            switchMap(({ id }: { id: string }) =>
                this.ingredientManagementService.getIngredients({ ids: [id] }).pipe(
                    map((paginatedIngredients: PaginatedResults<Ingredient>) =>
                        actions.getIngredientSuccess({
                            ingredient: paginatedIngredients.results?.[0],
                        }),
                    ),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getIngredientFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );

    getStatistics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getStatisticsRequest),
            switchMap(({ ingredientId }: { ingredientId: string }) =>
                this.ingredientViewerService.getStatistics(ingredientId).pipe(
                    map((statistics: Statistics) => actions.getStatisticsSuccess({ statistics })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getStatisticsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
