import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { environment } from '@environments/leap/environment';

/** Constants */
import { EMAIL_VALIDATION_REGEX } from '@leap-common/constants/regexes';
import {
    EMAIL_ICON,
    EMAIL_LABEL,
    EMAIL_PLACEHOLDER,
    FORGOT_PASSWORD_SUBTITLE,
    FORGOT_PASSWORD_TEXT,
    RESET_PASSWORD_SUBTITLE,
    RESET_PASSWORD_TEXT,
    BACK_TO_LOGIN_TEXT,
    SUBMIT_TEXT,
} from '../../../../core/constants/auth';

/** Services - Facades */
import { ErrorHandlerService } from '@leap-common/services/error-handler.service';
import { ForgotPasswordService } from '../../../services/forgot-password.service';
import { FormValidationService } from '@leap-libs/form/src/lib/form-validation.service';
import { AlertsFacade } from '@leap-store/core/src/lib/ui/alerts/alerts.facade';

/** Interfaces - Enums */
import { HttpErrorResponse } from '@angular/common/http';
import Organization from '@apps/leap/src/app/shared/enums/organization.enum';
import Alert from '@leap-store/core/src/lib/ui/alerts/interfaces/alert.interface';
import DecoratedInputOptions from '@leap-libs/decorated-input/src/lib/decorated-input-options.interface';

@Component({
    selector: 'app-forgot-password',
    templateUrl: 'forgot-password.container.component.html',
    styleUrls: ['forgot-password.container.component.scss'],
})
export class ForgotPasswordContainerComponent implements OnInit {
    @HostBinding('class') classes = 'full-width';

    // organization
    organization: string = environment.organization;

    // text color
    isTextWhite: boolean;

    // form
    form: FormGroup;
    formEmailOptions: DecoratedInputOptions;
    formSubmitButtonTitle: string;
    emailValidators: ValidatorFn;

    // password reset requested
    submittedEmail: string;
    passwordResetRequested: boolean = false;

    readonly forgotPasswordTitle: string = FORGOT_PASSWORD_TEXT;
    readonly forgotPasswordSubtitle: string = FORGOT_PASSWORD_SUBTITLE;
    readonly resetPasswordTitle: string = RESET_PASSWORD_TEXT;
    readonly resetPasswordSubtitle: string = RESET_PASSWORD_SUBTITLE;
    readonly goBackToLoginText: string = BACK_TO_LOGIN_TEXT;

    constructor(
        private router: Router,
        private formBuilder: FormBuilder,
        private errorHandlerService: ErrorHandlerService,
        private forgotPasswordService: ForgotPasswordService,
        private formValidationService: FormValidationService,
        private alertsFacade: AlertsFacade,
    ) {}

    ngOnInit(): void {
        this.initializeFormValidators();
        this.initializeForm();
        this.initializeIsTextWhite();
    }

    /** Initializes the form validators. */
    initializeFormValidators(): void {
        // initialize email validators
        this.emailValidators = Validators.compose([
            this.formValidationService.requiredValidator({ required: 'Email is required' }),
            this.formValidationService.patternValidator(EMAIL_VALIDATION_REGEX, {
                pattern: 'Please type in a valid email',
            }),
        ]);
    }

    /** Initializes the form options */
    initializeForm(): void {
        this.form = this.formBuilder.group({
            email: [null, this.emailValidators],
        });

        this.formEmailOptions = {
            type: 'text',
            id: 'email',
            label: EMAIL_LABEL,
            placeholder: EMAIL_PLACEHOLDER,
            iconsBegin: [{ class: EMAIL_ICON }],
            iconsEnd: [],
        };

        this.formSubmitButtonTitle = SUBMIT_TEXT;
    }

    initializeIsTextWhite(): void {
        this.isTextWhite = environment.organization !== Organization.dmi;
    }

    onSubmit(submittedEmail: string): void {
        this.submittedEmail = submittedEmail;
        this.forgotPasswordService.forgotPasswordRequest(this.submittedEmail).subscribe(
            () => {
                this.passwordResetRequested = true;
            },
            (httpErrorResponse: HttpErrorResponse) => {
                const alert: Alert = this.errorHandlerService.handleErrorResponse(
                    httpErrorResponse.error,
                );

                // push error to ui facade
                this.alertsFacade.pushAlertEvent({ content: alert });
            },
        );
    }

    onFormBackClicked(isClicked: boolean): void {
        if (!isClicked) {
            return;
        }

        this.onBackClicked();
    }

    onBackClicked(): void {
        this.router.navigate(['auth/login']);
    }
}
