/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';

/** custom imports */
import { SynonymsService } from './services/synonyms.service';
import { BookmarksService } from '@leap-store/core/src/lib/data/bookmarks/services/bookmarks.service';
import * as actions from './synonyms.actions';
import Synonyms from './types/synonyms.type';
import BookmarkIds from '../../bookmarks/interfaces/bookmark-ids.interface';

@Injectable()
export class SynonymsEffects {
    constructor(
        private actions$: Actions,
        private synonymsService: SynonymsService,
        private bookmarksService: BookmarksService,
    ) {}

    getSynonyms$ = createEffect(() =>
        this.actions$.pipe(
            ofType(actions.getSynonymsRequest),
            switchMap(({ ids, bookmarkIds }: { ids: string[]; bookmarkIds: BookmarkIds }) =>
                (bookmarkIds
                    ? this.bookmarksService.getSynonyms(bookmarkIds)
                    : this.synonymsService.getSynonyms(ids)
                ).pipe(
                    map((synonyms: Synonyms) => actions.getSynonymsSuccess({ synonyms })),
                    catchError((errorResponse: HttpErrorResponse) =>
                        of(actions.getSynonymsFailure({ errorResponse })),
                    ),
                ),
            ),
        ),
    );
}
