export const EMPTY_STRING: string = '';
export const WHITESPACE: string = ' ';
export const DOT: string = '.';
export const LINE_BREAK: string = '<br/>';
export const ELLIPSIS: string = '...';
export const DASH: string = '-';
export const LOWER_THAN: string = '<';
export const PERCENT: string = '%';
export const ASTERISK: string = '*';
export const DEFAULT_TRUNCATION_LIMIT: number = 30;
export const MINIMUM: string = 'Min';
export const MAXIMUM: string = 'Max';
export const MINIMUM_DISPLAYED_VALUE: number = 0.01;
export const CURRENT_YEAR: number = new Date().getFullYear();
export const US_LOCALE: string = 'en-US';
export const NOT_AVAILABLE: string = 'n/a';
export const BETA_TEXT: string = 'BETA';
export const PROCEED_TEXT: string = 'Proceed';
export const SAVE_TEXT: string = 'Save';
export const SORT_BY_LABEL: string = 'Sort by ';
export const CANCEL_TEXT: string = 'Cancel';
export const UPLOAD_TEXT: string = 'Upload';
export const WELCOME_TEXT: string = 'Welcome to';
export const FROZEN_COLUMN_CLASS_NAME: string = '.datatable-row-left';
export const TABLE_HEADER_HEIGHT: number = 50;
export const TABLE_ROW_HEIGHT: number = 49;
export const NO_OP_CALLBACK: () => void = (): void => {};
export const TOOLTIP_OPEN_DELAY: number = 500;
export const FORM_CLEAR_BUTTON_CLASS: string = 'fa-xmark';
export const FORM_EXPAND_BUTTON_CLASS: string = 'fa-ellipsis';
export const FORM_CIRCULAR_CLEAR_BUTTON_CLASS: string = 'fa-circle-xmark';
export const MODAL_CLOSE_BUTTON_CLASS: string = 'fa-solid fa-xmark';
export const DOI_URL: string = 'https://doi.org/';
export const REFERENCE_TAG: string = 'ref';
export const DELETE_CONFIRMATION_TEXT: string = 'Yes, delete it';
export const DELETE_CANCEL_TEXT: string = 'No, keep it';
export const OUT_OF: string = 'out of';
export const ET_AL: string = 'et al.';
export const NUMBER_WORDS: string[] = ['zero', 'one', 'two', 'three', 'four', 'five'];
export const DEFAULT_DATE_FORMAT: string = 'dd MMM yyyy HH:mm:ss';
