/** third-party imports */
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';

/** custom imports */
import { ExplainabilityService } from './services/explainability.service';
import * as explainabilityActions from './explainability.actions';
import SortingOrder from '@leap-common/enums/sorting-order.enum';
import PaginatedResults from '@leap-common/interfaces/paginated-results.interface';
import ExplorationPath from '../paths/interfaces/exploration-path.interface';

@Injectable()
export class ExplainabilityEffects {
    constructor(private actions$: Actions, private explainabilityService: ExplainabilityService) {}

    getPaths$ = createEffect(() =>
        this.actions$.pipe(
            ofType(explainabilityActions.getPathsRequest),
            mergeMap(
                ({
                    parentInsightId,
                    sourceId,
                    targetId,
                    sourceCategories,
                    targetCategories,
                    sourceSubcategories,
                    targetSubcategories,
                    relations,
                    sortingOrder,
                }: {
                    parentInsightId: string;
                    sourceId: string;
                    targetId: string;
                    sourceCategories: string[];
                    targetCategories: string[];
                    sourceSubcategories: string[];
                    targetSubcategories: string[];
                    relations: string[];
                    sortingOrder: SortingOrder;
                }) =>
                    this.explainabilityService
                        .getPaths(
                            sourceId,
                            targetId,
                            sourceCategories,
                            targetCategories,
                            sourceSubcategories,
                            targetSubcategories,
                            relations,
                            sortingOrder,
                        )
                        .pipe(
                            map((paginatedPaths: PaginatedResults<ExplorationPath>) =>
                                explainabilityActions.getPathsSuccess({
                                    parentInsightId,
                                    paginatedPaths,
                                    sortingOrder,
                                }),
                            ),
                            catchError((errorResponse: HttpErrorResponse) =>
                                of(explainabilityActions.getPathsFailure({ errorResponse })),
                            ),
                        ),
            ),
        ),
    );
}
