import { Component, HostBinding, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/leap/environment';

/** Constants */
import { FEATURE_CARD_ITEMS } from '../../../constants/landing';

/** Interfaces */
import MenuItem from '@leap-libs/main-menu/src/lib/menu-item.interface';

@Component({
    selector: 'app-landing',
    templateUrl: 'landing.container.component.html',
    styleUrls: ['landing.container.component.scss'],
})
export class LandingContainerComponent implements OnInit {
    @HostBinding('class') classes = 'content-wrapper';

    organization: string;

    readonly featureCardItems: MenuItem[] = FEATURE_CARD_ITEMS;

    constructor(private router: Router) {}

    ngOnInit(): void {
        this.initializeOrganization();
    }

    initializeOrganization(): void {
        this.organization = environment.organization;
    }

    setBackground(): Record<string, string> {
        return {
            'background-image': `url('assets/graphics/${this.organization}/full-background.png')`,
            'background-repeat': 'no-repeat',
            'background-size': 'cover',
            'background-position': 'right',
        };
    }

    onFeatureCardClicked({
        path,
        shouldOpenInNewTab,
    }: {
        path: string;
        shouldOpenInNewTab?: boolean;
    }): void {
        if (shouldOpenInNewTab) {
            window.open(path, '_blank');
        } else {
            this.router.navigate([path ? path : '/']);
        }
    }
}
